import navigationReducer from "./navigationReducer";
import loginReducer from "./loginReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    navigation: navigationReducer,
    login: loginReducer
});

export default rootReducer;
