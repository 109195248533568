import React from "react";
import { IconButton, Typography, Container, Button, SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as GeogebraLogo } from "../img/Geogebra.svg";
import { TextFields, Input, EmojiObjects, Image, Delete } from "@material-ui/icons";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { useHistory } from "react-router-dom";

export default props => {
    const history = useHistory();
    return (
        <Container maxWidth="md">
            <div style={{ height: "40px" }} />
            <Typography variant="h3" gutterBottom>
                The Editor
            </Typography>
            <Typography variant="h5" gutterBottom>
                You can start using the Origo Editor with minimal computer experience.
            </Typography>
            <Typography variant="body1" gutterBottom>
                The Editor is used to create digital pages, which can be constructed på using the following atoms:
            </Typography>
            <div style={{ margin: "8px 0 8px 0" }}>
                <IconButton aria-label="delete">
                    <TextFields fontSize="large" />
                </IconButton>
                <IconButton aria-label="delete">
                    <Input fontSize="large" />
                </IconButton>
                <IconButton aria-label="delete">
                    <SvgIcon component={GeogebraLogo} viewBox="0 0 300 276.6" />
                </IconButton>
                <IconButton aria-label="delete">
                    <EmojiObjects fontSize="large" />
                </IconButton>
                <IconButton aria-label="delete">
                    <Image fontSize="large" />
                </IconButton>
            </div>
            <Typography variant="body1" gutterBottom>
                and they are individual discussed in the sections{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/text")}>
                    Text
                </Link>
                ,{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/answer")}>
                    Answer
                </Link>
                ,{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/geogebra")}>
                    Geogebra
                </Link>
                ,{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/hint")}>
                    Hint
                </Link>{" "}
                and{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/image")}>
                    Image
                </Link>
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Headline
            </Typography>
            <Typography variant="body1" gutterBottom>
                The headline is set/changed by writing in the input field at the top at your digital page. The headline
                is the only mandatory field of the digital page, and should somehow indicate the content of the page.
                The better the headline describes the content, the better the page will be found among other users.
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Rearranging and Deleting
            </Typography>
            <Typography variant="body1" gutterBottom>
                You are always able to rearrange the order of atoms on yout digital page, by draggring the icon{" "}
                <IconButton aria-label="delete">
                    <DragHandleIcon fontSize="large" />
                </IconButton>{" "}
                to the right of your atoms.
            </Typography>

            <Typography variant="body1" gutterBottom>
                If you wish to delete an atom, then you have to click the desired atom, which will make a delete icon{" "}
                <IconButton aria-label="delete">
                    <Delete fontSize="large" />
                </IconButton>{" "}
                appear.
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Testing
            </Typography>
            <Typography variant="body1" gutterBottom>
                Testing the assignment means that you can test how the readers of your digital book will see the page.
                In order to test the page, click on the button{" "}
                <Button variant="contained" color="primary">
                    Test
                </Button>{" "}
                in the topbar of the editor. You can go back to editing by clicking on the button{" "}
                <Button variant="contained" color="primary">
                    Edit
                </Button>
                .
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Setting Variables
            </Typography>
            <Typography variant="body1" gutterBottom>
                By setting variables you can allow users to get different assignments. This part of the program is
                discussed in detail in section{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/variables")}>
                    Variables
                </Link>
                .
            </Typography>
        </Container>
    );
};
