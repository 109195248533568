import React from "react";
import { Typography, Container, Link, IconButton } from "@material-ui/core";
import { EmojiObjects } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export default props => {
    const history = useHistory();
    return (
        <Container maxWidth="md">
            <div style={{ height: "40px" }} />
            <Typography variant="h3" gutterBottom>
                Hint Atom
            </Typography>
            <Typography variant="body1" gutterBottom>
                The Hint Atom is meant for content that starts of being hidden, until the user decides to reveal it. The
                hint can be interpret like a normal Text Atom (see section{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/text")}>
                    Text Atom
                </Link>
                ). An icon{" "}
                <IconButton aria-label="delete">
                    <EmojiObjects fontSize="large" />
                </IconButton>{" "}
                will hide the text until the user clicks on it an it will then reveal the text.
            </Typography>
        </Container>
    );
};
