import React from "react";
import { Typography } from "@material-ui/core";

export default props => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    height: 30,
                    width: 30,
                    borderRadius: 15,
                    backgroundColor: props.color,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.2
                }}></div>
            <p style={{ marginLeft: -18, marginRight: 36, color: props.color }}>{props.sectionNumber}</p>
            <div>
                <Typography variant="h5" component="h2">
                    {props.title}
                </Typography>
            </div>
        </div>
    );
};
