import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import "simplebar/dist/simplebar.min.css";
import Atom from "../atoms/Atom";
import { Button, TextField, Typography } from "@material-ui/core";
import { db, getDoc, setDoc } from "../../firestore";
import Render from "./Render";

const slideUp = keyframes`
    0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    }
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
`;

const Overline = styled.div`
    width: 100%;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
`;

const Content = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    font-family: "Open Sans", sans-serif;
    -webkit-animation: ${slideUp} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: ${slideUp} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const AtomBox = styled.div`
    margin: 15px 0 15px 0;
`;

const ReproduceError = props => {
    const [errorReport, setErrorReport] = useState(null);
    const [molecule, setMolecule] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        (async () => {
            setErrorReport(await getDoc(db.collection("errorReports").doc(props.match.params.errorReportId)));
        })();
    }, [props.match.params.errorReportId]);

    useEffect(() => {
        if (!errorReport) return;
        (async () => {
            const molecule = await getDoc(db.collection("molecules").doc(errorReport.moleculeId));
            molecule.atoms = molecule.atoms.map(atom => {
                atom.key = Math.random();
                return atom;
            });
            setMolecule(molecule);
        })();
    }, [errorReport]);

    const getAtomComponent = (atom, i) => {
        if (!atom.key) atom.key = Math.random();

        const atomProps = {
            atom: atom,
            index: i,
            edit: false,
            rand: errorReport.variableRow,
            random: molecule.random[errorReport.variableRow],
            showCorrect: true,
            done: () => {},
            registerEvent: () => {}
        };
        return (
            <AtomBox key={atom.key}>
                <Atom {...atomProps} />
            </AtomBox>
        );
    };

    if (!molecule) return <></>;

    const onSend = async () => {
        await setDoc(db.collection("errorReports").doc(errorReport.id), {
            ...errorReport,
            groupOwnerMessage: message
        });
        alert("Sendt!");
    };

    return (
        <div style={{ backgroundColor: "#fff", padding: "24px" }}>
            <Overline>
                <Typography variant="h5" component="h2">
                    <Render text={molecule.title} />
                </Typography>
            </Overline>
            <Content>{molecule.atoms.map(getAtomComponent)}</Content>
            <br />
            <Typography variant="h6">Besked fra elev:</Typography>
            {errorReport.message}
            <Typography variant="h6">Er dette en fejl i opgaven? Send en fejlrapport til skaberen:</Typography>
            <TextField
                style={{ width: "100%" }}
                label="Tilføj evt. en kommentar"
                value={message}
                onChange={e => setMessage(e.target.value)}
                multiline
            />
            <br />
            <br />
            <Button variant="contained" onClick={onSend}>
                Send fejlrapport
            </Button>
        </div>
    );
};

export default ReproduceError;
