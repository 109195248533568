import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../config";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { login } from "../actions";
import "./firebaseui-styling.global.css";
import { db, setDoc, increment } from "../firestore";
import firebase from "firebase";
import { Provider } from "../hooks";

import logo from "../img/o_logo.svg";

const uiConfig = {
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.PhoneAuthProvider.PROVIDER_ID],
    credentialHelper: "none",
    signInFlow: "popup",
    callbacks: {
        signInSuccess: () => false
    }
};

const Login = props => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.login.user);
    useEffect(
        () =>
            auth.onAuthStateChanged(async user => {
                if (!user) return;
                const doc = await db.collection("users").doc(user.uid).get();
                const userData = { id: user.uid, email: user.email, name: user.displayName };
                if (doc.exists) {
                    await dispatch(login({ user: Object.assign(userData, doc.data()) }));
                    await setDoc(
                        db.collection("users").doc(user.uid),
                        Object.assign(doc.data(), { lastLogin: Date.now() })
                    );
                    return;
                }
                await setDoc(db.collection("users").doc(user.uid), userData);
                await db.collection("stats").doc("users").set({ count: increment }, { merge: true });
                await dispatch(login({ user: userData }));
            }),
        [dispatch]
    );
    if (!user)
        return (
            <>
                <img src={logo} style={{ objectFit: "contain", display: "block" }} height={100} alt="" />
                <p style={{ textAlign: "center", display: "block" }}>
                    Tryk på "Sign in with email" for at oprette dig med din email
                </p>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </>
        );
    return <Provider>{props.children}</Provider>;
};

export default Login;
