import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SimpleBar from "simplebar-react";
import { db, getCollection, getDoc } from "../../firestore";

const useStyles = makeStyles({
    root: {
        width: 400,
        height: 500,
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden"
    },
    header: {
        padding: "16px 16px 0 16px"
    },
    fade: {
        backgroundImage: "linear-gradient(to bottom, transparent, #ffffff)",
        minHeight: "100px",
        position: "sticky",
        bottom: "-16px"
    }
});

const PreviewBookPopover = props => {
    const classes = useStyles();
    const [molecules, setMolecules] = useState(undefined);

    useEffect(() => {
        (async () => {
            setMolecules(
                await Promise.all(props.book.molecules.map(m => getDoc(db.collection("molecules").doc(m.id))))
            );
        })();
    }, [props.book.molecules]);

    return (
        <Card className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h5" component="h2">
                    {props.book.title}
                </Typography>
            </div>
            <SimpleBar>
                <CardContent>
                    {molecules === undefined ? (
                        <div>Loading...</div>
                    ) : (
                        <ol>
                            {molecules.map((molecule, i) => (
                                <li>
                                    <b>{i + 1}</b> {molecule.title}
                                </li>
                            ))}
                        </ol>
                    )}
                    {<div className={classes.fade} />}
                </CardContent>
            </SimpleBar>
        </Card>
    );
};

export default PreviewBookPopover;
