import React, { useState, useEffect } from "react";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import {
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    FormControlLabel,
    Checkbox,
    Popover
} from "@material-ui/core";
import Tags from "../Tags";
import { db, getCollection } from "../../firestore";
import { useHistory } from "react-router-dom";
import PreviewPagePopover from "./PreviewPagePopover.js";
import Render from "../exercise/Render.js";
import { useOwnMolecules } from "../../hooks/molecules";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: "100%",
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #c4c4c4",
        borderRadius: 5
    },
    addButton: {
        position: "sticky",
        bottom: 0,
        height: 50,
        backgroundColor: "#96e8a6",
        color: "white",
        "&:hover": { backgroundColor: "#96e8a6" }
    },
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1)
    }
}));

const FindPage = props => {
    const history = useHistory();
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [molecules, setMolecules] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [moleculeHover, setMoleculeHover] = useState(null);
    const [showMyPages, setShowMyPages] = useState(false);
    const ownMolecules = useOwnMolecules();

    useEffect(() => {
        (async () => {
            if (!ownMolecules) return;
            if (tags.length === 0 && !showMyPages) {
                const latestMolecules = await getCollection(
                    db.collection("molecules").where("public", "==", true).orderBy("createTime", "desc").limit(20)
                );
                setMolecules(latestMolecules);
                return;
            }
            const publicMolecules = showMyPages
                ? []
                : await getCollection(
                      db.collection("molecules").where("public", "==", true).where("tags", "array-contains", tags[0])
                  );
            const allMoleculesWithDuplicates = [...ownMolecules, ...publicMolecules];
            const allMoleculesObj = Object.fromEntries(allMoleculesWithDuplicates.map(m => [m.id, m]));
            const allMolecules = Object.values(allMoleculesObj);
            setMolecules(
                allMolecules.filter(m => {
                    let include = true;
                    tags.forEach(t => {
                        if (!(m.tags || []).includes(t)) include = false;
                    });
                    return include;
                })
            );
        })();
    }, [tags, showMyPages, ownMolecules]);

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMoleculeHover(null);
    };

    return (
        <>
            {props.visible && (
                <div className={classes.root}>
                    <List className={classes.list}>
                        <ListSubheader>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showMyPages}
                                        onChange={event => setShowMyPages(event.target.checked)}
                                    />
                                }
                                label="Only show my pages"
                            />
                            <Tags onChange={setTags} />
                        </ListSubheader>
                        {molecules.map(molecule => (
                            <ListItem
                                key={molecule.id}
                                role={undefined}
                                dense
                                button
                                onClick={() => props.onAdd(molecule)}
                                aria-owns={!!anchorEl ? "mouse-over-popover" : undefined}
                                aria-haspopup="true"
                                onMouseEnter={event => {
                                    setAnchorEl(event.currentTarget);
                                    setMoleculeHover(molecule);
                                }}
                                onMouseLeave={handlePopoverClose}>
                                <ListItemIcon style={{ minWidth: "35px" }}>
                                    <ArrowLeftIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Render text={molecule.title} />
                                </ListItemText>
                            </ListItem>
                        ))}
                        <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.paper
                            }}
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: props.noPagesYet ? "right" : "left"
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: props.noPagesYet ? "left" : "right"
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus>
                            {moleculeHover && <PreviewPagePopover key={moleculeHover.id} molecule={moleculeHover} />}
                        </Popover>
                        <ListItem button className={classes.addButton} onClick={() => history.push("/MyPages")}>
                            Create your own pages
                        </ListItem>
                    </List>

                    <div style={{ minWidth: "100px" }} />
                </div>
            )}
        </>
    );
};

export default FindPage;
