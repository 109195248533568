import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default props => {
    const [groupId, setGroupId] = useState("");
    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Join a group</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are not in any group yet. Would you like to join a group then paste in the group id. If you are
                    not joining a group then simply click "Not now".
                </DialogContentText>
                <TextField
                    value={groupId}
                    autoFocus
                    margin="dense"
                    id="groupId"
                    label="Group ID"
                    type="text"
                    fullWidth
                    onChange={e => setGroupId(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Not now
                </Button>
                <Button onClick={() => props.handleJoin(groupId)} color="primary">
                    Join group
                </Button>
            </DialogActions>
        </Dialog>
    );
};
