import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, FormControlLabel, TextField, Typography, Button, Checkbox } from "@material-ui/core";
import { db, setDoc } from "../firestore";
import styled from "styled-components";
import { storage } from "../config";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        paddingBottom: "80px"
    },
    feedbackButton: {
        borderRadius: "0px",
        color: "#fff",
        backgroundColor: "#96e8a6"
    }
}));

const FeedbackButton = styled.button`
    position: absolute;
    right: 16px;
    bottom: 16px;
    background-color: #353839;
    color: #fff;
    border: none;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
`;

export default props => {
    const user = useSelector(state => state.login.user);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mailAllow, setMailAllow] = useState(true);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setDoc(db.collection("feedback").doc(), { user: user.id, sent: false, message: message });
        setAnchorEl(null);
        setMessage("");
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={classes.feedbackButton}>
                Brok dig
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popOver}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}>
                <Typography className={classes.typography}>
                    <Typography variant="h6" gutterBottom>
                        Brok dig over hvad som helst. På forhånd tak!
                    </Typography>

                    <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-static"
                        label="Skriv dit brok her"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        multiline
                    />
                    <label>Du kan også vedhæfte en fil: </label>
                    <br />
                    <input type="file" onChange={async e => setFile(e.target.files[0])} />
                    <br />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={mailAllow}
                                onChange={() => setMailAllow(!mailAllow)}
                                name="checkedMailAllow"
                                color="primary"
                            />
                        }
                        label="Origo må gerne sende en mail når problemet er løst"
                    />
                    <FeedbackButton
                        onClick={async () => {
                            let extension = "";
                            try {
                                extension = /(?:\.([^.]+))?$/.exec(file.name)[1];
                            } catch (err) {
                                console.log(err);
                            }
                            const fileId = Math.random().toString(36).substr(2, 9) + "." + extension;
                            setDoc(db.collection("feedback").doc(), {
                                user: user.id,
                                sent: true,
                                message: message,
                                mailAllow: mailAllow,
                                file: file ? fileId : ""
                            });
                            if (file)
                                await storage
                                    .ref()
                                    .child("feedback/" + fileId)
                                    .put(file);
                            setAnchorEl(null);
                            setMessage("");
                            setFile(null);
                            window.alert("Sendt! Vi er meget taknemmelige for dit brok :) Send gerne mere");
                        }}>
                        Send
                    </FeedbackButton>
                </Typography>
            </Popover>
        </div>
    );
};
