import React from "react";
import { Card, Grid, CardContent, CardActions, Button, Typography } from "@material-ui/core";

export default props => {
    return (
        <Card style={{ minHeight: "100%" }}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h4" gutterBottom>
                            1
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>
                            Topic
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4" gutterBottom>
                            6
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>
                            Books
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button style={{ backgroundColor: props.color, width: "100%", color: "#fff" }} onClick={props.onCopy}>
                    Import to My Groups
                </Button>
            </CardActions>
        </Card>
    );
};
