import React, { useState } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import styled, { keyframes } from "styled-components";
import "simplebar/dist/simplebar.min.css";
import Atom from "../atoms/Atom";
import { Button, Typography } from "@material-ui/core";
import { registerEvent } from "../../firestore";
import Render from "./Render";
import gen from "random-seed";
import ReportError from "../ReportError.js";

const slideUp = keyframes`
    0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    }
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
`;

const Overline = styled.div`
    width: 100%;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
`;

const Content = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    font-family: "Open Sans", sans-serif;
    -webkit-animation: ${slideUp} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: ${slideUp} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const AtomBox = styled.div`
    margin: 15px 0 15px 0;
`;

const shuffle = (a, seed) => {
    const r = gen(seed);
    const rand = () => r(Number.MAX_SAFE_INTEGER) / Number.MAX_SAFE_INTEGER;
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(rand() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
};

export default props => {
    const user = useSelector(state => state.login.user);
    const [done, setDone] = useState({ [props.molecule.id]: props.molecule.atoms.map(() => -1) });
    const [allDone, setAllDone] = useState({});
    const [localRand, setLocalRand] = useState(Number(window.localStorage.getItem("rand")));

    const order = props.molecule.random.map((r, i) => i);
    shuffle(order, props.seed);
    const rand = props.progress
        ? order[(props.progress[props.molecule.id] + localRand) % props.molecule.random.length]
        : props.seed;
    const random = props.molecule.random[rand] || {};

    const getAtomComponent = (atom, i) => {
        if (!atom.key) atom.key = Math.random();

        const atomProps = {
            atom: atom,
            index: i,
            edit: false,
            rand: rand,
            random: random,
            showCorrect: props.showCorrect,
            done: !props.match
                ? () => {}
                : async weight => {
                      if (allDone[props.molecule.id]) return;
                      if (done[props.molecule.id][i] !== -1) return;
                      const doneNew = update(done, { [props.molecule.id]: { [i]: { $set: weight } } });
                      await setDone(doneNew);
                      let all = true;
                      let sum = 0;
                      doneNew[props.molecule.id].forEach(d => {
                          if (d === -1) all = false;
                          else sum += d;
                      });
                      if (!all) return;
                      if (sum === 0 && props.progress[props.molecule.id] > 0) return;
                      await setAllDone(update(allDone, { [props.molecule.id]: { $set: true } }));
                      if (props.onDone) props.onDone();
                      if (user)
                          await registerEvent({
                              time: new Date().getTime(),
                              userId: user.id,
                              groupId: props.match.params.groupId,
                              doodleId: props.match.params.doodleId,
                              moleculeId: props.molecule.id,
                              type: "done"
                          });
                  },
            registerEvent: !props.match
                ? () => {}
                : async data => {
                      if (props.match.params.doodleId === undefined) return;
                      if (data.type === "giveUp") {
                          const r = Math.floor(Math.random() * 1000000);
                          window.localStorage.setItem("rand", r);
                          setLocalRand(r);
                      }
                      await registerEvent(
                          Object.assign(data, {
                              time: new Date().getTime(),
                              userId: user.id,
                              groupId: props.match.params.groupId,
                              doodleId: props.match.params.doodleId,
                              moleculeId: props.molecule.id,
                              atomIndex: i
                          })
                      );
                  }
        };
        return (
            <AtomBox key={`${atom.key}_${rand}`}>
                <Atom {...atomProps} />
            </AtomBox>
        );
    };

    if (!props.molecule) return <></>;

    return (
        <div style={{ backgroundColor: "#fff", padding: "24px" }}>
            {!props.hideTitle && (
                <Overline>
                    <Typography variant="h5" component="h2">
                        <Render text={props.molecule.title} />
                    </Typography>
                </Overline>
            )}
            <Content>{props.molecule.atoms.map((atom, i) => getAtomComponent(atom, i))}</Content>
            {props.progress && props.progress[props.molecule.id] >= props.molecule.repeat && (
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "100%", height: "60px", position: "sticky", bottom: 0, color: "#fff" }}
                    onClick={props.next || (() => {})}>
                    Næste opgave
                </Button>
            )}
            <br />
            <br />
            <ReportError group={props.group} doodle={props.doodle} molecule={props.molecule} variableRow={rand} />
        </div>
    );
};
