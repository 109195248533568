import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import styled from "styled-components";

const FocusedStyle = styled.div`
    border: solid 3px ${props => (props.focused ? "#96e8a6" : "none")};
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
`;

const DeleteOption = styled.div`
    display: ${props => (props.focused ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const Atom = styled.div`
    flex-grow: 1;
`;

export default props => {
    const [focused, setFocused] = useState(props.initialVal);
    return (
        <ClickAwayListener onClickAway={() => setFocused(false)}>
            <FocusedStyle onClick={() => setFocused(true)} focused={focused}>
                <Atom>
                    {React.Children.map(props.children, child => React.cloneElement(child, { focused: focused }))}
                </Atom>
                <DeleteOption style={{ width: "50px" }} onClick={props.onDelete} focused={focused}>
                    <DeleteIcon />
                </DeleteOption>
            </FocusedStyle>
        </ClickAwayListener>
    );
};
