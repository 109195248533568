import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TransferList from "./TransferList.js";
import MemberList from "./MemberList.js";
import StatisticsOverview from "./StatisticsOverview.js";

const StyledTabs = withStyles(theme => ({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > div": {
            width: "80%",
            backgroundColor: theme.palette.primary.main
        }
    }
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
    root: {
        textTransform: "none",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        "&:focus": {
            opacity: 1
        }
    }
}))(props => <Tab disableRipple {...props} />);

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ flex: 1, display: "flex", overflow: "hidden" }}
            {...other}>
            {value === index && <div style={{ display: "flex", flex: 1, overflow: "hidden" }}> {children}</div>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    }
}));

export default props => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                <StyledTab label="Content" />
                <StyledTab label="Statistics" />
                <StyledTab label="Members" />
            </StyledTabs>
            <TabPanel value={value} index={0}>
                <TransferList selected={props.group.doodles} update={props.update} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StatisticsOverview groupId={props.groupId} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <MemberList groupId={props.groupId} />
            </TabPanel>
        </div>
    );
};
