import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import Render from "../exercise/Render.js";

export default props => {
    return (
        <Draggable
            key={props.molecule.id + "_" + props.index}
            draggableId={props.molecule.id + "_" + props.index}
            index={props.index}>
            {dragProps => (
                <div>
                    <Card
                        className={{
                            maxWidth: 400,
                            maxHeight: 200,
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "hidden"
                        }}
                        ref={dragProps.innerRef}
                        {...dragProps.draggableProps}
                        {...dragProps.dragHandleProps}
                        style={dragProps.draggableProps.style}>
                        <CardContent style={{ textAlign: "left", minHeight: 150 }}>
                            <Typography
                                className={{
                                    fontSize: 14,
                                    alignItems: "flex-start"
                                }}
                                color="textSecondary"
                                gutterBottom>
                                Repeat: {props.molecule.repeat}
                            </Typography>

                            <Render text={props.molecule.title} />
                        </CardContent>
                    </Card>
                </div>
            )}
        </Draggable>
    );
};
