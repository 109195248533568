import React, { useEffect } from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import { Hidden, Drawer, CssBaseline } from "@material-ui/core";
import TopBar from "./TopBar.js";
import SideNavigator from "./SideNavigator.js";
import Home from "../home/Home.js";
import MyGroup from "../groups/MyGroup.js";
import MyPages from "../pages/MyPages.js";
import Editor from "../pages/Editor.js";
import Profile from "../Profile.js";
import TodoBoard from "../TodoBoard.js";
import GroupOverview from "../home/GroupOverview.js";
import LearnNavigator from "../learn/LearnNavigator.js";
import CreateBookPage from "../books/CreateBookPage.js";
import MyBooks from "../books/MyBooks.js";
import Login from "../Login.js";
import Layout from "../../constants/Layout.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ReproduceError from "../exercise/ReproduceError.js";

const drawerWidth = Layout.drawerWidth;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "100%"
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flex: 1,
        height: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    scroll: {
        overflowY: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column"
    }
}));

const Navigator = props => {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [topBarStyle, setTopBarStyle] = React.useState({ color: "inherit", backgroundColor: "transparent" });
    const [bookOpen, setBookOpen] = React.useState(false);
    const history = useHistory();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleTopBarStyleChange = style => {
        setTopBarStyle(style);
    };
    useEffect(
        () =>
            history.listen(location => {
                setTopBarStyle({ backgroundColor: "transparent", color: "inherit" });
                setBookOpen(false);
            }),
        [history]
    );
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Login>
            <div className={classes.root}>
                <CssBaseline />
                {!bookOpen && (
                    <>
                        <TopBar handleDrawerToggle={handleDrawerToggle} style={topBarStyle} />

                        <nav className={classes.drawer}>
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Hidden smUp implementation="css">
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    anchor={theme.direction === "rtl" ? "right" : "left"}
                                    open={mobileOpen}
                                    classes={{
                                        paper: classes.drawerPaper
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                        onBackdropClick: () => setMobileOpen(false)
                                    }}>
                                    <SideNavigator close={handleDrawerToggle} />
                                </Drawer>
                            </Hidden>
                            <Hidden xsDown implementation="css">
                                <Drawer
                                    classes={{
                                        paper: classes.drawerPaper
                                    }}
                                    variant="permanent"
                                    open>
                                    <SideNavigator />
                                </Drawer>
                            </Hidden>
                        </nav>
                    </>
                )}
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div className={classes.scroll}>
                        <Switch>
                            <Route path="/Profile" exact component={Profile} />
                            <Route path="/Home" exact component={Home} />
                            <Route
                                path="/Home/:groupId"
                                exact
                                render={props => <GroupOverview {...props} changeTopBar={handleTopBarStyleChange} />}
                            />
                            <Route
                                path="/Home/:groupId/:doodleId"
                                exact
                                render={props => <LearnNavigator {...props} setBookOpen={bool => setBookOpen(bool)} />}
                            />
                            <Route path="/MyGroup/:groupId" exact component={MyGroup} />
                            <Route path="/MyBooks" exact component={MyBooks} />
                            <Route path="/MyBooks/:doodleId" exact component={CreateBookPage} />
                            <Route path="/MyPages" exact component={MyPages} />
                            <Route path="/MyPages/:moleculeId" exact component={Editor} />
                            <Route path="/TodoBoard" exact component={TodoBoard} />
                            <Route path="/ErrorReports/:errorReportId" exact component={ReproduceError} />
                            <Redirect exact from="*" to="/" />
                        </Switch>
                    </div>
                </main>
            </div>
        </Login>
    );
};

export default Navigator;
