import React from "react";
import { Card, CardContent } from "@material-ui/core";

export default props => {
    return (
        <>
            {!props.hidden && (
                <div style={{ padding: "10px 24px 10px 24px", backgroundColor: "#96e8a6" }}>
                    <Card style={{ backgroundColor: "#f4f4f4" }}>
                        <CardContent style={{ minHeight: 150, minWidth: 230 }}></CardContent>
                    </Card>
                </div>
            )}
        </>
    );
};
