import React from "react";
import styled from "styled-components";
import Geogebra, { GeogebraEdit, GeogebraIcon } from "./Geogebra.js";
import Text, { TextEdit, TextIcon } from "./Text.js";
import Input, { InputEdit, InputIcon } from "./Input.js";
import Hint, { HintEdit, HintIcon } from "./Hint.js";
import Image, { ImageEdit, ImageIcon } from "./Image.js";
import Graph, { GraphEdit, GraphIcon } from "./Graph.js";

const AtomName = styled.div`
    font-size: 15px;
    color: #96e8a6;
    margin-bottom: 5px;
`;

export const atomTypes = ["Text", "Input", "Geogebra", "Hint", "Image", "Graph"];

export const atomName = type => {
    switch (type) {
        case "Input":
            return "Answer";
        default:
            return type;
    }
};

export const atomIcon = type => {
    switch (type) {
        case "Text":
            return <TextIcon />;
        case "Input":
            return <InputIcon />;
        case "Hint":
            return <HintIcon />;
        case "Geogebra":
            return <GeogebraIcon />;
        case "Image":
            return <ImageIcon />;
        case "Graph":
            return <GraphIcon />;
        default:
            return <></>;
    }
};

const Atom = props => {
    switch (props.atom.type) {
        case "Text":
            return <Text {...props} />;
        case "Input":
            return <Input {...props} />;
        case "Hint":
            return <Hint {...props} />;
        case "Geogebra":
            return <Geogebra {...props} />;
        case "Image":
            return <Image {...props} />;
        case "Graph":
            return <Graph {...props} />;
        default:
            return <></>;
    }
};

const AtomEdit = props => {
    switch (props.atom.type) {
        case "Text":
            return <TextEdit {...props} />;
        case "Input":
            return <InputEdit {...props} />;
        case "Hint":
            return <HintEdit {...props} />;
        case "Geogebra":
            return <GeogebraEdit {...props} />;
        case "Image":
            return <ImageEdit {...props} />;
        case "Graph":
            return <GraphEdit {...props} />;
        default:
            return <></>;
    }
};

export default props =>
    props.edit ? (
        <>
            <AtomName>{props.focused && atomName(props.atom.type)}</AtomName>
            <AtomEdit {...props} />
        </>
    ) : (
        <Atom {...props} />
    );
