import firebase from "firebase";
import app from "./config";

const db = app.firestore();
const increment = firebase.firestore.FieldValue.increment(1);

const getDoc = async docRef => {
    const doc = await docRef.get();
    if (!doc.exists) return null;
    return Object.assign({ id: docRef.id }, doc.data());
};

const listenDoc = (docRef, callback) => {
    let count = 0;
    return docRef.onSnapshot(doc => {
        ++count;
        callback(Object.assign({ id: docRef.id }, doc.data()), count);
    });
};

const setDoc = async (docRef, data) => {
    const doc = Object.assign({}, data);
    delete doc.id;
    doc.updateTime = new Date().getTime();
    if (!doc.createTime) {
        const data = (await docRef.get()).data();
        if (data) doc.createTime = data.createTime;
        if (!doc.createTime) doc.createTime = doc.updateTime;
    }
    await docRef.set(doc);
};

const setDocSameUpdateTime = async (docRef, data) => {
    const doc = Object.assign({}, data);
    delete doc.id;
    await docRef.set(doc);
};

const getCollection = async colRef => {
    const querySnapshot = (await colRef.get()).docs;
    const docs = [];
    querySnapshot.forEach(doc => {
        docs.push(Object.assign({ id: doc.id }, doc.data()));
    });
    return docs;
};

const listenCollection = (colRef, callback) => {
    return colRef.onSnapshot(querySnapshot => {
        const docs = [];
        querySnapshot.forEach(doc => {
            docs.push(Object.assign({ id: doc.id }, doc.data()));
        });
        callback(docs);
    });
};

const registerEvent = async data => {
    const batch = db.batch();
    const eventDocRef = db.collection("events").doc();
    batch.set(eventDocRef, data);
    if (
        ["done", "incorrect", "giveUp"].includes(data.type) &&
        data.userId &&
        data.groupId &&
        data.doodleId &&
        data.moleculeId
    ) {
        const userDocRef = db.collection("progress").doc(data.userId);
        const groupDocRef = userDocRef.collection("groups").doc(data.groupId);
        const doodleDocRef = groupDocRef.collection("doodles").doc(data.doodleId);
        batch.set(
            doodleDocRef,
            {
                [data.type === "done" ? "count" : "failCount"]: {
                    [data.moleculeId]: increment
                }
            },
            { merge: true }
        );
        if (data.type === "done") await db.collection("stats").doc("solved").set({ count: increment }, { merge: true });
    }
    await batch.commit();
    await updateProgressGroup(data);
};

const updateProgressGroup = async data => {
    const userDocRef = db.collection("progress").doc(data.userId);
    const groupDocRef = userDocRef.collection("groups").doc(data.groupId);
    const doodleDocRef = groupDocRef.collection("doodles").doc(data.doodleId);
    let percent = 0;
    const count = ((await doodleDocRef.get()).data() || {}).count || {};
    const molecules = (await db.collection("doodles").doc(data.doodleId).get()).data().molecules;
    molecules.forEach(m => {
        m = typeof m === "string" ? { id: m, repeat: 1 } : m;
        percent += Math.min((count[m.id] || 0) / Number(m.repeat), 1);
    });
    percent /= molecules.length;
    await groupDocRef.set({ [data.doodleId]: percent }, { merge: true });
    await updateProgressUser(data);
};

const updateProgressUser = async data => {
    const userDocRef = db.collection("progress").doc(data.userId);
    const groupDocRef = userDocRef.collection("groups").doc(data.groupId);
    let percent = 0;
    const doodlePercent = (await groupDocRef.get()).data();
    const doodles = (await db.collection("groups").doc(data.groupId).get()).data().doodles;
    doodles.forEach(doodle => (percent += doodlePercent[doodle.id] || 0));
    percent /= doodles.length;
    await userDocRef.set({ [data.groupId]: percent }, { merge: true });
};

export {
    db,
    getDoc,
    listenDoc,
    setDoc,
    setDocSameUpdateTime,
    getCollection,
    listenCollection,
    registerEvent,
    increment
};
