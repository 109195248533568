import firebase from "firebase";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const config = {
    apiKey: "AIzaSyAK7L7ZEWOA9JQtzRcBXMMRFHiJTt9dDgk",
    authDomain: "origo-dev-79b80.firebaseapp.com",
    databaseURL: "https://origo-dev-79b80.firebaseio.com",
    projectId: "origo-dev-79b80",
    storageBucket: "origo-dev-79b80.appspot.com",
    messagingSenderId: "1047859775798",
    appId: "1:1047859775798:web:18bf3dee58182c80"
};

const app = firebase.initializeApp(config);

export const firestore = app.firestore();

firestore.settings({
    experimentalForceLongPolling: true,
    useFetchStreams: false
});

export const storage = app.storage();

export const auth = app.auth();

export default app;
