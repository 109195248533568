import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Render, { getVarNames } from "../exercise/Render.js";
import IconButton from "@material-ui/core/IconButton";
import EmojiObjects from "@material-ui/icons/EmojiObjects";
import update from "immutability-helper";

const Textarea = styled.div`
    > textarea {
        margin-top: 5px;
        width: 100%;
        border: none;
        outline: none;
        font-style: italic;
        color: #686868;
        font-size: 0.8em;
    }
`;

const HintText = styled.div`
    margin-top: 5px;
    width: 100%;
    border: none;
    outline: none;
    font-style: italic;
    color: #686868;
    font-size: 1em;
`;

export default props => {
    const [activated, setActivated] = useState(false);
    useEffect(() => {
        props.done(0);
    }, [props]);
    return activated ? (
        <HintText>
            <Render text={props.atom.text} variables={props.random} />
        </HintText>
    ) : (
        <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{ marginLeft: "-10px", marginTop: "-10px" }}
            onClick={() => setActivated(true)}>
            <EmojiObjects />
        </IconButton>
    );
};

export const HintEdit = props => (
    <Textarea>
        <TextareaAutosize
            placeholder="Write some text..."
            value={props.atom.text}
            onChange={e =>
                props.didUpdate(
                    update(props.atom, {
                        text: { $set: e.target.value },
                        variables: { $set: getVarNames(e.target.value) }
                    })
                )
            }
        />
    </Textarea>
);

export const HintIcon = props => <EmojiObjects />;
