import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SimpleBar from "simplebar-react";
import Exercise from "../exercise/Exercise.js";

const useStyles = makeStyles({
    root: {
        width: 400,
        height: 500,
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden"
    },
    header: {
        padding: "16px 16px 0 16px"
    },
    scale: {
        transform: "scale(0.5) translate(-50%,-50%)",
        width: "200%"
    },
    fade: {
        backgroundImage: "linear-gradient(to bottom, transparent, #ffffff)",
        minHeight: "100px",
        position: "sticky",
        bottom: "-16px"
    }
});

export default props => {
    const classes = useStyles();
    const ref = useRef();
    const [scaled, setScaled] = useState(false);

    useEffect(() => {
        if (!ref.current) return;
        setTimeout(() => setScaled(true), 500);
    }, [ref.current]);

    return (
        <Card className={classes.root} style={{ visibility: scaled ? "visible" : "hidden" }}>
            <div className={classes.header}>
                <Typography variant="h5" component="h2">
                    {props.molecule.title}
                </Typography>
            </div>
            <SimpleBar>
                <CardContent>
                    <div ref={ref} className={scaled ? classes.scale : ""}>
                        <Exercise
                            molecule={props.molecule}
                            seed={Math.floor(Math.random() * props.molecule.random.length)}
                            hideTitle={true}
                            showCorrect={true}
                        />
                    </div>
                    {<div className={classes.fade} />}
                </CardContent>
            </SimpleBar>
        </Card>
    );
};
