import React from "react";
import Layout from "../../constants/Layout.js";
import AccountSettingsMenu from "./AccountSettingsMenu.js";
import { Button, AppBar, Toolbar, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${Layout.drawerWidth}px)`,
            marginLeft: Layout.drawerWidth
        },
        boxShadow: "none",
        pointerEvents: "none"
    },
    menuButton: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    hidden: {
        visibility: "hidden"
    },
    root: {
        "& > *": {
            margin: theme.spacing(1)
        }
    }
}));
export default props => {
    const classes = useStyles();
    return (
        <AppBar position="fixed" color="transparent" className={classes.appBar}>
            <Toolbar>
                <div className={classes.hidden}>
                    <IconButton edge="start" className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                </div>

                <div className={classes.hidden}>
                    <IconButton>
                        <ArrowBackIcon />
                    </IconButton>
                </div>

                <div style={{ flexGrow: 1, pointerEvents: "auto" }}>
                    <div className={classes.root}>
                        <Button
                            variant={props.tab === "joined" ? "contained" : ""}
                            disableElevation
                            onClick={() => props.setTab("joined")}>
                            Joined
                        </Button>
                        <Button
                            variant={props.tab === "public" ? "contained" : ""}
                            disableElevation
                            onClick={() => props.setTab("public")}>
                            Public
                        </Button>
                    </div>
                </div>

                <div className={classes.hidden}>
                    <AccountSettingsMenu />
                </div>
            </Toolbar>
        </AppBar>
    );
};
