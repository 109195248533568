import React, { useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, CircularProgress, Popover, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Render from "../exercise/Render.js";
import { makeStyles } from "@material-ui/core/styles";

// Inspired by the former Facebook spinners.
const useStylesCustom = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    top: {
        color: theme.palette.primary,
        position: "absolute",
        left: 0
    },
    circle: {
        strokeLinecap: "round"
    }
}));

function CustomCircularProgress(props) {
    const classes = useStylesCustom();

    return (
        <div className={classes.root}>
            <CircularProgress variant="static" className={classes.bottom} size={35} thickness={4} value={100} />
            <CircularProgress
                variant="static"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle
                }}
                size={35}
                thickness={4}
                value={props.progress}
                style={{ color: props.color }}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1),
        marginLeft: 300,
        marginTop: -100
    }
}));

const BookContentList = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [popupMoleculeTitle, setPopupMoleculeTitle] = useState("");
    const [popupCount, setPopupCount] = useState(0);
    const [popupFailCount, setPopupFailCount] = useState(0);
    return (
        <List>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                onClose={() => setAnchorEl(null)}
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus>
                <Typography>
                    <b>
                        <Render text={popupMoleculeTitle} />
                    </b>
                    Rigtige svar: <b style={{ color: "green" }}>{popupCount}</b> <br />
                    Forkerte svar: <b style={{ color: "red" }}>{popupFailCount}</b> <br />
                    Få dobbelt så mange rigtige som forkerte <br /> for at gøre den helt grøn (
                    <b style={{ color: "green" }}>{Math.max(2 * popupFailCount - popupCount, 0)}</b> mere)
                </Typography>
            </Popover>
            {props.molecules.map((mol, i) => {
                const percent = (100 * props.progress[mol.id]) / mol.repeat;
                return (
                    <ListItem
                        key={i}
                        onClick={() => props.setIndex(i)}
                        style={{ cursor: "pointer" }}
                        selected={i === props.index}
                        onMouseEnter={event => {
                            setAnchorEl(event.currentTarget);
                            setPopupMoleculeTitle(mol.title);
                            setPopupCount(props.progress[mol.id]);
                            setPopupFailCount(props.progressFailCount[mol.id]);
                        }}
                        onMouseLeave={() => setAnchorEl(null)}
                        aria-owns={anchorEl ? "mouse-over-popover" : undefined}>
                        <ListItemIcon>
                            {percent >= 100 ? (
                                <CheckCircleIcon fontSize="large" style={{ color: props.color[mol.id] }} />
                            ) : (
                                <CustomCircularProgress progress={percent} color={props.color[mol.id]} />
                            )}
                        </ListItemIcon>
                        <ListItemText>
                            <Render text={mol.title} />
                        </ListItemText>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default BookContentList;
