import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";

export default props => {
    const history = useHistory();
    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            ReactGA.initialize("G-8NVNK5Z67D");
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
            history.listen(location => ReactGA.send({ hitType: "pageview", page: location.pathname }));
        }
    }, [history]);
    return <></>;
};
