import React, { useState, useEffect } from "react";
import { renderVars, getVarNames } from "../exercise/Render.js";
import vm from "vm-browserify";
import JXGBoard from "./JXGBoard";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import update from "immutability-helper";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";

const defaultAttributes = `{
    "showCopyright": false,
    "axis": true,
    "boundingbox": [-10, 10, 10, -10]
}`;

const defaultCode = `graph.suspendUpdate();
const a = graph.create("slider", [[2, 8], [6, 8], [0, 3, 6]], { name: "a" });
const b = graph.create("slider", [[2, 7], [6, 7], [0, 2, 6]], { name: "b" });
const A = graph.create("slider", [[2, 6], [6, 6], [0, 3, 6]], { name: "A" });
const B = graph.create("slider", [[2, 5], [6, 5], [0, 3, 6]], { name: "B" });
const delta = graph.create("slider", [[2, 4], [6, 4], [0, 0, Math.PI]], { name: "&delta;" });
const c = graph.create(
    "curve",
    [
        t => A.Value() * Math.sin(a.Value() * t + delta.Value()),
        t => B.Value() * Math.sin(b.Value() * t),
        0,
        2 * Math.PI
    ],
    { strokeColor: "#00ff00", strokeWidth: 3 }
);
graph.unsuspendUpdate();`;

export default props => {
    useEffect(() => {
        props.done(0);
    }, [props]);
    return (
        <JXGBoard
            logic={canvas =>
                vm.runInNewContext(`(()=>{${renderVars({ text: props.atom.code, variables: props.random })}})()`, {
                    graph: canvas
                })
            }
            boardAttributes={vm.runInNewContext(
                `(()=>{${renderVars({
                    text: "return " + (props.atom.boardAttributes || defaultAttributes),
                    variables: props.random
                })}})()`,
                {}
            )}
            style={{
                width: 400,
                height: 400
            }}
        />
    );
};

export const GraphEdit = props => {
    const [code, setCode] = useState(props.atom.code || defaultCode);
    const [boardAttributes, setBoardAttributes] = useState(props.atom.boardAttributes || defaultAttributes);

    useEffect(() => {
        if (code === props.atom.code && boardAttributes === props.atom.boardAttributes) return;
        props.didUpdate(
            update(props.atom, {
                code: { $set: code },
                boardAttributes: { $set: boardAttributes },
                variables: { $set: getVarNames(code) }
            })
        );
    }, [props, code, boardAttributes]);

    return (
        <>
            Attributes
            <Editor
                value={boardAttributes}
                onValueChange={c => setBoardAttributes(c)}
                highlight={c => highlight(c, languages.js)}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12
                }}
            />
            Code
            <Editor
                value={code}
                onValueChange={c => setCode(c)}
                highlight={c => highlight(c, languages.js)}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12
                }}
            />
            <style>
                {`.token.cdata,.token.comment,.token.doctype,.token.prolog{color:#90a4ae}.token.punctuation{color:#9e9e9e}.namespace{opacity:.7}.token.boolean,.token.constant,.token.deleted,.token.number,.token.property,.token.symbol,.token.tag{color:#e91e63}.token.attr-name,.token.builtin,.token.char,.token.inserted,.token.selector,.token.string{color:#4caf50}.language-css .token.string,.style .token.string,.token.entity,.token.operator,.token.url{color:#795548}.token.atrule,.token.attr-value,.token.keyword{color:#3f51b5}.token.function{color:#f44336}.token.important,.token.regex,.token.variable{color:#ff9800}.token.bold,.token.important{font-weight:700}.token.italic{font-style:italic}.token.entity{cursor:help}`}
            </style>
            <a href="https://jsxgraph.org/docs/index.html" target="_blank" rel="noopener noreferrer">
                Click here to visit the JSXGraph Reference
            </a>
        </>
    );
};

export const GraphIcon = props => <MultilineChartIcon />;
