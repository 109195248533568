import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ListSubheader, List, ListItem, ListItemText, Typography, Divider, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import styled from "styled-components";

const Headline = styled.div`
    display: flex;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    > div {
        display: flex;
        flex-grow: 1;
        min-height: 56px;
        align-items: flex-start;
        padding-left: 24px;
        flex-direction: column;
        justify-content: center;
        @media (min-width: 600px) {
            min-height: 64px;
        }
        @media (min-width: 0px) and (orientation: landscape) {
            min-height: 48px;
        }
    }
`;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    }
}));

export default props => {
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div style={{ width: "240px" }}>
            <Headline>
                <div>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            history.push("/documentation");
                            props.close();
                        }}>
                        Origo-Doc
                    </Typography>
                    <p>v0.1</p>
                </div>
            </Headline>
            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Getting Started
                    </ListSubheader>
                }
                className={classes.root}>
                <ListItem
                    button
                    onClick={() => {
                        history.push("/documentation/editor");
                        props.close();
                    }}>
                    <ListItemText primary="The Editor" />
                </ListItem>
            </List>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Editor
                    </ListSubheader>
                }
                className={classes.root}>
                <ListItem button onClick={handleClick}>
                    <ListItemText primary="Atoms" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            <ListItemText
                                primary="Text"
                                onClick={() => {
                                    history.push("/documentation/editor/atoms/text");
                                    props.close();
                                }}
                            />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText
                                primary="Answer"
                                onClick={() => {
                                    history.push("/documentation/editor/atoms/answer");
                                    props.close();
                                }}
                            />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText
                                primary="Geogebra"
                                onClick={() => {
                                    history.push("/documentation/editor/atoms/geogebra");
                                    props.close();
                                }}
                            />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText
                                primary="Image"
                                onClick={() => {
                                    history.push("/documentation/editor/atoms/image");
                                    props.close();
                                }}
                            />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemText
                                primary="Hint"
                                onClick={() => {
                                    history.push("/documentation/editor/atoms/hint");
                                    props.close();
                                }}
                            />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem button>
                    <ListItemText
                        primary="Variables"
                        onClick={() => {
                            history.push("/documentation/editor/variables");
                            props.close();
                        }}
                    />
                </ListItem>
            </List>
        </div>
    );
};
