import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SimpleBar from "simplebar-react";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: "-3px 3px 15px rgba(0, 0, 0, 0.1)",
        marginTop: "20px"
    },
    vote: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }
}));

export default props => {
    const classes = useStyles();
    return (
        <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
            <Container maxWidth="lg" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                <Typography variant="h4" gutterBottom>
                    Future developement
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    We put to a vote what to develope and in which order. We strongly encourage all users to vote on
                    which functions they find most needed. If a function isn't on the list, then please use the chat
                    icon in the right bottom corner and we will put it on the list right away.
                </Typography>
                <List className={classes.root}>
                    <ListItem>
                        <ListItemAvatar>
                            <div className={classes.vote}>
                                <ArrowUpwardIcon />
                                <div style={{ textAlign: "center", width: "24px" }}>0</div>
                                <ArrowDownwardIcon />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Chat mellem Lærer og elev"
                            secondary="Mulighed for at elever og lærer kan chatte med hinanden når der løses opgaver."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <div className={classes.vote}>
                                <ArrowUpwardIcon />
                                <div style={{ textAlign: "center", width: "24px" }}>0</div>
                                <ArrowDownwardIcon />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Sætte bredde og højde på billeder"
                            secondary="En indstlling således at billeder kan skaleres op og ned i størrelse."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <div className={classes.vote}>
                                <ArrowUpwardIcon />
                                <div style={{ textAlign: "center", width: "24px" }}>0</div>
                                <ArrowDownwardIcon />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Overblik over progress"
                            secondary="Et overblik over din samlede progress i hele origo universet."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <div className={classes.vote}>
                                <ArrowUpwardIcon />
                                <div style={{ textAlign: "center", width: "24px" }}>0</div>
                                <ArrowDownwardIcon />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Kunne lave multiple choice spørgsmål"
                            secondary="Opgradere editoren under 'My Pages' til at kunne lave multiple choice spørgsmål."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <div className={classes.vote}>
                                <ArrowUpwardIcon />
                                <div style={{ textAlign: "center", width: "24px" }}>0</div>
                                <ArrowDownwardIcon />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Mobil applikation"
                            secondary="Mulighed for at løse opgaverne på mobilen."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <div className={classes.vote}>
                                <ArrowUpwardIcon />
                                <div style={{ textAlign: "center", width: "24px" }}>0</div>
                                <ArrowDownwardIcon />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Kunne post ønsker til funtioner"
                            secondary="Mulighed for at lave post på denne side, så man ikke behøvede at skrive til os med ønsker."
                        />
                    </ListItem>
                </List>
            </Container>
        </SimpleBar>
    );
};
