import React from "react";
import styled from "styled-components";

const BookCoverStyle = styled.div`
    cursor: pointer;
    display: block;
    width: 120px;
    height: 160px;
    position: relative;
    background: white;
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 9px 20px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    -webkit-transition: box-shadow 0.3s linear;
    transition: box-shadow 0.3s linear;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 9px 20px 0 rgba(0, 0, 0, 0.45);
    }
    &::after,
    &::before {
        content: "";
        display: block;
        width: inherit;
        height: inherit;
        position: absolute;
        z-index: -1;
        top: 0;
        background: white;
        border: 1px solid #d9d9d9;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    &::before {
        left: -3px;
    }
    &::after {
        left: -6px;
    }

    > div {
        width: inherit;
        height: inherit;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transition: all 0.45s ease;
        transition: all 0.45s ease;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: ${props => props.color};
        &:hover {
            -webkit-transform: rotateY(-25deg);
            transform: rotateY(-25deg);
            box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.2);
        }
    }
`;

export default props => <BookCoverStyle {...props} />;
