import React, { useState, useEffect } from "react";
import { Drawer } from "@material-ui/core";
import Loading from "../Loading.js";
import BookContentList from "./BookContentList.js";
import Exercise from "../exercise/Exercise.js";
import { makeStyles } from "@material-ui/core/styles";
import { db, getDoc } from "../../firestore";
import update from "immutability-helper";

const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        minHeight: "100%"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        backgroundColor: "#fff"
    }
}));

const time = Date.now();
const mole = window.location.search
    .slice(1)
    .split("&")
    .map(s => {
        const a = s.split(",");
        return { id: a[0], repeat: Number(a[1]) };
    });

export default props => {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [molecules, setMolecules] = useState(null);
    const [progress, setProgress] = useState(Object.fromEntries(mole.map(m => [m.id, 0])));
    useEffect(() => {
        Promise.all(
            mole.map(async m => ({ ...(await getDoc(db.collection("molecules").doc(m.id))), repeat: m.repeat }))
        ).then(setMolecules);
    }, []);
    const hash = str => Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
    if (!molecules) return <Loading />;
    if (molecules.length === 0) return <div>This book is empty</div>;
    const molecule = molecules[index];
    const seed = molecule ? hash(time + molecule.id) : "";

    return (
        <div className={classes.root}>
            <nav className={classes.drawer}>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    variant="permanent"
                    open>
                    <BookContentList
                        molecules={molecules}
                        progress={progress}
                        molecule={molecule}
                        index={index}
                        setIndex={setIndex}
                    />
                </Drawer>
            </nav>
            <main className={classes.content}>
                {molecule && (
                    <Exercise
                        {...props}
                        molecule={molecule}
                        seed={seed}
                        key={seed + progress[molecule.id]}
                        showCorrect={false}
                        next={() => setIndex(index + 1)}
                        progress={progress}
                        onDone={() =>
                            setProgress(update(progress, { [molecule.id]: { $set: progress[molecule.id] + 1 } }))
                        }
                    />
                )}
            </main>
        </div>
    );
};
