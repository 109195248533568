import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { db, getDoc } from "./firestore";

if (window.location.pathname === "/") {
    (async () => {
        window.document.getElementById("root").style = "display: none";
        const frontpage = window.document.getElementById("frontpage");
        frontpage.style = "display: block";
        const col = db.collection("stats");
        frontpage.contentWindow.postMessage({ solved: (await getDoc(col.doc("solved"))).count }, "*");
        frontpage.contentWindow.postMessage({ books: (await getDoc(col.doc("books"))).count }, "*");
        frontpage.contentWindow.postMessage({ created: (await getDoc(col.doc("created"))).count }, "*");
        frontpage.contentWindow.postMessage({ users: (await getDoc(col.doc("users"))).count }, "*");
    })();
} else {
    ReactDOM.render(<App />, document.getElementById("root"));
}

serviceWorkerRegistration.register();
