import React from "react";
import { Typography, Container, Link } from "@material-ui/core";
import mathFormulation from "../img/exampleMathFormulation.png";
import mathFormulationCompiled from "../img/mathFormulationCompiled.png";
import { useHistory } from "react-router-dom";

export default props => {
    const history = useHistory();
    return (
        <Container maxWidth="md">
            <div style={{ height: "40px" }} />
            <Typography variant="h3" gutterBottom>
                Text Atom
            </Typography>
            <Typography variant="body1" gutterBottom>
                The text atom can contain plain text, math formulations and variable definitons.
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Math formulations
            </Typography>
            <Typography variant="body1" gutterBottom>
                Origo supports the LaTeX type-setting system. In order to use the LaTeX type-setting, a text should be
                encapsulated with two dollar signs ($$ text $$). An example could be:
            </Typography>
            <img src={mathFormulation} height={175} alt="math formulation" />
            <Typography variant="body1" gutterBottom>
                which compiles to:
            </Typography>
            <img src={mathFormulationCompiled} height={125} alt="math formulation compiled" />
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Variable Definitions
            </Typography>
            <Typography variant="body1" gutterBottom>
                Variables are defined with an @-sign. The notation @V will define a variable V. You can explore the use
                of variables more in section{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/variables")}>
                    Variables
                </Link>
                .
            </Typography>
        </Container>
    );
};
