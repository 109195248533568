import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { db, setDoc, getCollection } from "../../firestore";
import BookCover from "./BookCover.js";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import LearnCardAdd from "../home/LearnCardAdd.js";
import { increment } from "../../firestore";

const AddBook = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        width: 120px;
        height: 160px;
    }
`;

export default props => {
    const [doodles, setDoodles] = useState([]);
    const user = useSelector(state => state.login.user);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            setDoodles(
                await getCollection(
                    db.collection("doodles").where("owner", "==", user.id).orderBy("updateTime", "desc")
                )
            );
        })();
    }, [user.id]);

    return (
        <>
            <Grid container>
                <Grid item xs={6} sm={3} lg={2}>
                    <AddBook>
                        <LearnCardAdd
                            onClick={async () => {
                                const docRef = db.collection("doodles").doc();
                                const doodle = {
                                    id: docRef.id,
                                    owner: user.id,
                                    title: "",
                                    public: false,
                                    molecules: []
                                };
                                await setDoc(docRef, doodle);
                                await db.collection("stats").doc("books").set({ count: increment }, { merge: true });
                                history.push("/MyBooks/" + doodle.id);
                            }}></LearnCardAdd>
                    </AddBook>
                </Grid>
                {doodles.map((doodle, i) => (
                    <Grid key={i} item xs={6} sm={3} lg={2}>
                        <BookCover doodle={doodle} i={i} onClick={() => history.push("/MyBooks/" + doodle.id)} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
