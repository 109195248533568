import React from "react";
import { Card, CardContent, TextField, IconButton } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Render from "../exercise/Render.js";
import update from "immutability-helper";
import DeleteIcon from "@material-ui/icons/Delete";

export default props => {
    return (
        <DragDropContext onDragEnd={props.onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
                {propsDrop => (
                    <div
                        ref={propsDrop.innerRef}
                        style={{ display: "flex", flexDirection: "column" }}
                        {...propsDrop.droppableProps}>
                        {props.molecules.map((m, i) => (
                            <Draggable key={m.id + "_" + i} draggableId={m.id + "_" + i} index={i}>
                                {dragProps => (
                                    <div
                                        onClick={() => props.changeIndex(i)}
                                        style={{
                                            padding: "10px 24px 0 24px",
                                            backgroundColor:
                                                props.currentMolecule?.id === m.id ? "#96e8a6" : "transparent"
                                        }}>
                                        <Card
                                            className={{
                                                maxWidth: 400,
                                                maxHeight: 200,
                                                display: "flex",
                                                flexDirection: "column",
                                                overflowY: "hidden",
                                                cursor: "move"
                                            }}
                                            ref={dragProps.innerRef}
                                            {...dragProps.draggableProps}
                                            {...dragProps.dragHandleProps}
                                            style={dragProps.draggableProps.style}>
                                            <CardContent style={{ textAlign: "left", minHeight: 150, cursor: "move" }}>
                                                <Render text={m.title} />
                                                <TextField
                                                    id="standard-number"
                                                    label="Gentagelser"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    value={m.repeat}
                                                    onChange={e =>
                                                        props.setMolecules(
                                                            update(props.molecules, {
                                                                [i]: { repeat: { $set: e.target.value } }
                                                            })
                                                        )
                                                    }
                                                />
                                                <IconButton
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        props.setMolecules(
                                                            update(props.molecules, {
                                                                $splice: [[i, 1]]
                                                            })
                                                        );
                                                        if (props.currentMolecule?.id === props.molecules[i].id)
                                                            props.setCurrentMolecule(null);
                                                    }}>
                                                    <DeleteIcon color="secondary" />
                                                </IconButton>
                                            </CardContent>
                                        </Card>
                                        <div
                                            style={{
                                                minHeight: "10px",
                                                justifyContent: "center",
                                                display: "flex",
                                                padding: "10px"
                                            }}>
                                            <p
                                                style={{
                                                    color: props.currentMolecule?.id === m.id ? "#fff" : "inherit"
                                                }}>
                                                {i + 1}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {propsDrop.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
