import React, { useState, useEffect } from "react";
import { storage } from "../../config";
import update from "immutability-helper";
import { Image } from "@material-ui/icons";

export default props => {
    const [src, setSrc] = useState(null);
    useEffect(() => {
        props.done(0);
        (async () => {
            try {
                const src = await storage.ref().child(props.atom.img).getDownloadURL();
                await setSrc(src);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [props]);
    return <img src={src} alt={props.atom.name} style={{ width: "25%" }} />;
};

export const ImageEdit = props => {
    return props.atom.img ? (
        props.atom.name
    ) : (
        <input
            type="file"
            onChange={async e => {
                const file = e.target.files[0];
                const id = Math.random().toString(36).substr(2, 9) + ".img";
                await storage.ref().child(id).put(file);
                props.didUpdate(update(props.atom, { img: { $set: id }, name: { $set: file.name } }));
            }}
        />
    );
};

export const ImageIcon = props => <Image />;
