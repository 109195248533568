import React from "react";
import { Typography, Container, Link } from "@material-ui/core";
import exampleAnswer1 from "../img/exampleAnswer1.png";
import exampleAnswer2 from "../img/exampleAnswer2.png";
import exampleAnswer3 from "../img/exampleAnswer3.png";
import correctIndicator from "../img/correctIndicator.png";
import { useHistory } from "react-router-dom";

export default props => {
    const history = useHistory();
    return (
        <Container maxWidth="md">
            <div style={{ height: "40px" }} />
            <Typography variant="h3" gutterBottom>
                Answer Atom
            </Typography>
            <Typography variant="body1" gutterBottom>
                The answer atom kan take a text, number or a variable. The right answer is indicated in the input.
                Examples could be:
            </Typography>
            <img src={exampleAnswer1} height={90} alt="example answer 1" />
            <Typography variant="body1" gutterBottom>
                Where the answer is <span style={{ fontWeight: 600 }}>42</span>.
            </Typography>
            <img src={exampleAnswer2} height={96} alt="example answer 2" />
            <Typography variant="body1" gutterBottom>
                Where the answer is <span style={{ fontWeight: 600 }}>awesome</span>.
            </Typography>
            <img src={exampleAnswer3} height={99} alt="example answer 3" />
            <Typography variant="body1" gutterBottom>
                Where the answer is defined in <span style={{ fontWeight: 600 }}>variable V</span>, which is explained
                more in depth in section{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/variables")}>
                    Variables
                </Link>
                .
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Testing Input
            </Typography>
            <Typography variant="body1" gutterBottom>
                The answer can be tested when testing the digital page. The right answer should trigger
            </Typography>
            <img src={correctIndicator} height={40} alt="correct indicator" />
            <Typography variant="body1" gutterBottom>
                Learn more about testing pages in section{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor")}>
                    The Editor
                </Link>
            </Typography>
        </Container>
    );
};
