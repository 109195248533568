import React, { useState, useEffect } from "react";
import { db, getDoc, getCollection } from "../../firestore";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import SimpleBar from "simplebar-react";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: "auto",
        overflowX: "hidden",
        padding: "0 30px 0 30px"
    }
}));

const loadUsers = async groupId => {
    const usersIds = [
        ...new Set(
            (await getCollection(db.collection("users_groups").where("groupId", "==", groupId))).map(
                userGroup => userGroup.userId
            )
        )
    ];
    const us = await Promise.all(usersIds.map(async userId => await getDoc(db.collection("users").doc(userId))));
    us.sort((a, b) => ((a.name || "").toLowerCase() > (b.name || "").toLowerCase() ? 1 : -1));
    return us;
};

export default props => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        (async () => await setUsers(await loadUsers(props.groupId)))();
    }, [props.groupId]);

    return (
        <div className={classes.root}>
            <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
                <List component="nav" aria-label="main mailbox folders">
                    {users.map(user => (
                        <>
                            <ListItem button>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary={user.name} />
                                <ListItemSecondaryAction>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={async () => {
                                            if (!window.confirm(`You are about remove ${user.name} from the group.`))
                                                return;
                                            if (!window.confirm("Are you sure?")) return;
                                            const docs = (
                                                await db
                                                    .collection("users_groups")
                                                    .where("userId", "==", user.id)
                                                    .where("groupId", "==", props.groupId)
                                                    .get()
                                            ).docs;
                                            await Promise.all(
                                                docs.map(
                                                    async userGroup =>
                                                        await db.collection("users_groups").doc(userGroup.id).delete()
                                                )
                                            );
                                            await setUsers(await loadUsers(props.groupId));
                                        }}>
                                        Remove student
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </>
                    ))}
                </List>
            </SimpleBar>
        </div>
    );
};
