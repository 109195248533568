import React, { useState, forwardRef, useImperativeHandle } from "react";
import styled, { keyframes } from "styled-components";

const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
        border: 1px solid red;
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
        border: 1px solid red;
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
        border: 1px solid red;
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
        border: 1px solid red;
    }
`;

const jump = keyframes`
    0% {
        transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
        opacity: 0.2;
    }
    30% {
        transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
        opacity: 1;
    }
    45% {
        transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    60% {
        transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    75% {
        transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    90% {
        transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    100% {
        transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
`;

const Shake = styled.button`
    float: right;
    padding: 5px 25px 5px 25px;
    border: none;
    color: white;
    background-color: #353839;
    animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
`;

const Correct = styled.button`
    float: right;
    padding: 5px 25px 5px 25px;
    border: none;
    color: white;
    background-color: #96e8a6;
    animation: ${jump} 1s ease-out both;
`;

const Button = styled.button`
    float: right;
    padding: 5px 25px 5px 25px;
    border: none;
    color: white;
    background-color: #353839;
`;

export default forwardRef((props, ref) => {
    const [animate, setAnimate] = useState(false);
    const [correct, setCorrect] = useState(false);

    const startAnimation = isCorrect => {
        setCorrect(isCorrect);
        setAnimate(true);
    };

    const click = () => {
        if (animate) return;
        props.checkAnswers();
    };

    useImperativeHandle(ref, () => ({
        startAnimation,
        click
    }));

    const onAnimationEnd = () => setAnimate(false);

    if (!animate)
        return (
            <Button ref={ref} onClick={click}>
                Svar
            </Button>
        );

    if (correct) return <Correct onAnimationEnd={onAnimationEnd}>Rigtigt!</Correct>;

    return <Shake onAnimationEnd={onAnimationEnd}>Forkert</Shake>;
});
