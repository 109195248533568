export default (state = {}, action) => {
    switch (action.type) {
        case "SET":
            return Object.assign({}, state, action.payload);
        case "SET_CURRENT_QUESTION":
            return Object.assign({}, state, { question: action.payload });
        default:
            return state;
    }
};
