import React from "react";
import Layout from "../../constants/Layout.js";
import AccountSettingsMenu from "./AccountSettingsMenu.js";
import { AppBar, Toolbar, IconButton, Input } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${Layout.drawerWidth}px)`,
            marginLeft: Layout.drawerWidth
        },
        boxShadow: "none",
        pointerEvents: "none"
    },
    menuButton: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    hidden: {
        visibility: "hidden"
    }
}));
export default props => {
    const classes = useStyles();
    return (
        <AppBar position="fixed" color="transparent" className={classes.appBar}>
            <Toolbar>
                <div className={classes.hidden}>
                    <IconButton edge="start" className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                </div>

                <div className={classes.hidden}>
                    <IconButton>
                        <ArrowBackIcon />
                    </IconButton>
                </div>

                <div style={{ flexGrow: 1, pointerEvents: "auto" }}>
                    <Input
                        value={props.title}
                        placeholder="(no title)"
                        onChange={props.onChange}
                        style={{ width: "100%" }}
                    />
                </div>

                <div className={classes.hidden}>
                    <AccountSettingsMenu />
                </div>
            </Toolbar>
        </AppBar>
    );
};
