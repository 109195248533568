import React from "react";
import { Typography, Container } from "@material-ui/core";

const Geogebra = props => (
    <Container maxWidth="md">
        <div style={{ height: "40px" }} />
        <Typography variant="h3" gutterBottom>
            Geogebra Atom
        </Typography>
        <Typography variant="body1" gutterBottom>
            The Origo Editor supports math visulization throught Geogebra files. You can upload a Geogebra file in this
            atom, and the file will then be displayed when testing the page.
        </Typography>
    </Container>
);

export default Geogebra;
