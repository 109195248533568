import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import {
    Drawer,
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";

const NoVariablesDiv = styled.div`
    height: 100px;
    display: flex;
    flex-direction: column;
    color: lightgrey;
    justify-content: center;
    text-align: center;
`;

export default props => {
    const [open, setOpen] = useState(false);
    const [varNames, setVarNames] = useState([]);
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (!file) return;
        setFile(null);
        const reader = new FileReader();
        reader.onload = async event => {
            const text = event.target.result;
            const lines = text.split(/\r*\n/);
            const separator = (lines[0].match(/[^\w]/) || [","])[0];
            const matrix = lines.map(line => line.split(separator));
            const vars = matrix[0].map(v => (v.length === 0 ? Math.random() : v));
            matrix.splice(0, 1);
            const variables = [];
            matrix.forEach(array => {
                const result = {};
                array.forEach((value, i) => (result[vars[i]] = value));
                if (Object.keys(result).length < vars.length) return;
                variables.push(result);
            });
            props.update(update(props.molecule, { random: { $set: variables } }));
        };
        reader.readAsText(file);
    }, [file, props]);

    return (
        <>
            <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
                <AppBar position="static" color="menu">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            Set variables
                        </Typography>
                        {props.molecule.random.length +
                            " row" +
                            (props.molecule.random.length === 1 ? "" : "s") +
                            " in total"}
                        <Button onClick={() => props.update(update(props.molecule, { random: { $push: [{}] } }))}>
                            Add row
                        </Button>
                        <input
                            id="upload-csv-file"
                            multiple
                            style={{ display: "none" }}
                            type="file"
                            accept=".csv"
                            onClick={e => (e.target.value = null)}
                            onChange={e => {
                                setFile(e.target.files[0]);
                            }}
                        />
                        <label htmlFor="upload-csv-file">
                            <Button variant="contained" color="default" component="span">
                                Upload CSV
                            </Button>
                        </label>
                    </Toolbar>
                </AppBar>
                {varNames.length === 0 ? (
                    <NoVariablesDiv c>No variables defined</NoVariablesDiv>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {varNames.map(v => (
                                        <TableCell>{v}</TableCell>
                                    ))}
                                    <TableCell style={{ width: "50px" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.molecule.random.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={varNames.length}>
                                            <NoVariablesDiv>No rows added</NoVariablesDiv>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    props.molecule.random.map((r, i) => (
                                        <TableRow>
                                            <TableCell>{i + 1}</TableCell>
                                            {varNames.map(v => (
                                                <TableCell>
                                                    <input
                                                        style={{ minWidth: "100%" }}
                                                        value={r[v]}
                                                        onChange={e =>
                                                            props.update(
                                                                update(props.molecule, {
                                                                    random: {
                                                                        [i]: { [v]: { $set: e.target.value } }
                                                                    }
                                                                })
                                                            )
                                                        }></input>
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        props.update(
                                                            update(props.molecule, {
                                                                random: { $splice: [[i, 1]] }
                                                            })
                                                        )
                                                    }
                                                    startIcon={<DeleteIcon />}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Drawer>
            <Button
                startIcon={<TableChartIcon />}
                onClick={() => {
                    const varNamesNew = [];
                    const seen = {};
                    props.molecule.atoms.forEach(atom =>
                        (atom.variables || []).forEach(v => {
                            if (seen[v]) return;
                            seen[v] = true;
                            varNamesNew.push(v);
                        })
                    );
                    setVarNames(varNamesNew);
                    setOpen(true);
                }}>
                Set Variables
            </Button>
        </>
    );
};
