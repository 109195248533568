import React, { useState } from "react";
import update from "immutability-helper";
import { TableRow, TableCell, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert, EmojiObjects, Error } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import { db, getDoc, setDoc, increment } from "../../firestore";
import Render from "../exercise/Render.js";
import { useErrorReports } from "../../hooks/errorReports";

export default props => {
    const errorReports = useErrorReports();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const renderCell = (columnId, row, i) => {
        switch (columnId) {
            case "title":
                return <Render text={row.title} />;
            case "public":
                return (
                    <Switch
                        checked={props.molecule.public || false}
                        color="primary"
                        onClick={e => {
                            e.stopPropagation();
                            props.update(update(props.molecule, { public: { $set: e.target.checked } }));
                        }}
                    />
                );
            case "hint":
                let hint = false;
                row.atoms.forEach(atom => {
                    if (atom.type === "Hint") hint = true;
                });
                return <EmojiObjects style={{ marginLeft: "10px" }} color={hint ? "primary" : "secondary"} />;
            case "errorReports":
                let errors = (errorReports || []).some(er => er.moleculeId === row.id);
                return errors ? <Error style={{ marginLeft: "10px" }} color="secondary" /> : <></>;
            case "edit":
                return (
                    <>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={e => {
                                e.stopPropagation();
                                setAnchorEl(null);
                            }}>
                            <MenuItem
                                onClick={async e => {
                                    e.stopPropagation();
                                    if (!window.confirm("Duplicate " + props.molecule.title + "?")) return;
                                    const molecule = await getDoc(db.collection("molecules").doc(row.id));
                                    delete molecule.createTime;
                                    delete molecule.updateTime;
                                    await setDoc(db.collection("molecules").doc(), molecule);
                                    await db
                                        .collection("stats")
                                        .doc("created")
                                        .set({ count: increment }, { merge: true });
                                    setAnchorEl(null);
                                }}>
                                Duplicate
                            </MenuItem>
                            <MenuItem
                                onClick={async e => {
                                    e.stopPropagation();
                                    if (!window.confirm("Delete " + props.molecule.title + "?")) return;
                                    await db.collection("molecules").doc(row.id).delete();
                                    setAnchorEl(null);
                                }}>
                                Delete
                            </MenuItem>
                        </Menu>
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <TableRow hover tabIndex={-1} key={props.i} onClick={() => props.editRow()}>
            {props.columns.map(column => (
                <TableCell key={column.id} align={column.align} style={{ width: column.width }}>
                    {renderCell(column.id, props.row, props.i)}
                </TableCell>
            ))}
        </TableRow>
    );
};
