import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import FindPage from "./FindPage.js";
import Exercise from "../exercise/Exercise.js";
import update from "immutability-helper";
import PageList from "./PageList.js";
import { useHistory } from "react-router-dom";

export default props => {
    const history = useHistory();
    const [molecules, setMolecules] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentMolecule, setCurrentMolecule] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        for (let i = 0; i < molecules.length - 1; ++i) {
            if (molecules[i].id === molecules[i + 1].id) {
                setMolecules(
                    update(molecules, {
                        $splice: [
                            [
                                i,
                                2,
                                update(molecules[i], {
                                    repeat: { $set: molecules[i].repeat + molecules[i + 1].repeat }
                                })
                            ]
                        ]
                    })
                );
                if (currentIndex === i + 1) setCurrentIndex(i);
                return;
            }
        }
    }, [molecules, currentIndex]);

    return (
        <div style={{ display: "flex", flexDirection: "Column", overflow: "hidden", height: "100vh" }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 10px",
                    borderBottom: "1px solid #ccc"
                }}>
                <p style={{ flex: 1, fontSize: 24, fontWeight: 600 }}>Origo Opgavesamler</p>
                <Button variant="contained" onClick={() => history.push("/MyPages")}>
                    Lav din egen opgave
                </Button>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    flex: 1
                }}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        overflow: "hidden"
                    }}>
                    <div
                        style={{
                            flex: 1,
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                        <div
                            style={{
                                flex: 2,
                                overflowY: "auto"
                            }}>
                            <FindPage onAdd={setCurrentMolecule} selectedMolecule={currentMolecule} />
                        </div>
                        <div
                            style={{
                                flex: 3,
                                borderRight: "1px solid #ccc"
                            }}>
                            {currentMolecule ? (
                                <>
                                    <Exercise
                                        molecule={currentMolecule}
                                        seed={Math.floor(Math.random() * currentMolecule.random.length)}
                                        hideTitle={false}
                                        showCorrect={false}
                                    />
                                    {!molecules.find(m => m.id === currentMolecule.id) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ float: "right", marginRight: 10 }}
                                            onClick={() =>
                                                setMolecules(
                                                    update(molecules, { $push: [{ ...currentMolecule, repeat: 1 }] })
                                                )
                                            }>
                                            Tilføj til playliste
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <div
                                    style={{
                                        height: "100%",

                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                    <p>Klik på en opgave til venstre for at få den vist her...</p>
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto",
                                padding: "0 10px"
                            }}>
                            {molecules.length === 0 && (
                                <div
                                    style={{
                                        padding: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#ccc",
                                        marginTop: 10
                                    }}>
                                    <p>Playlisten er tom...</p>
                                </div>
                            )}
                            <PageList
                                currentMolecule={currentMolecule}
                                setCurrentMolecule={setCurrentMolecule}
                                molecules={molecules}
                                setMolecules={setMolecules}
                                onDragEnd={e => {
                                    if (!e.destination) return;
                                    const i = e.source.index;
                                    const j = e.destination.index;
                                    if (i === j) return;
                                    const m = update(molecules, {
                                        $splice: [
                                            [i, 1],
                                            [j, 0, molecules[i]]
                                        ]
                                    });
                                    setMolecules(m);
                                    const currentIndex = molecules.findIndex(m => m.id === currentMolecule.id);
                                    if (currentIndex < i && currentIndex < j) return;
                                    if (currentIndex > i && currentIndex > j) return;
                                    if (currentIndex === i) return setCurrentMolecule(m[j]);
                                    if (i < j) return setCurrentMolecule(m[currentIndex - 1]);
                                    if (i > j) return setCurrentMolecule(m[currentIndex + 1]);
                                }}
                                changeIndex={i => setCurrentMolecule(molecules[i])}
                            />
                            <Button
                                variant="contained"
                                onClick={() => setModalOpen(true)}
                                style={{
                                    minWidth: "100%",
                                    marginTop: 10,
                                    backgroundColor: "rgb(26, 115, 232)",
                                    color: "#fff"
                                }}>
                                Del playlisten med dine elever
                            </Button>
                            <Dialog
                                onClose={() => setModalOpen(false)}
                                aria-labelledby="simple-dialog-title"
                                open={modalOpen}>
                                <DialogTitle id="simple-dialog-title">
                                    Kopiuer linket herunder og send det til dine elever
                                </DialogTitle>
                                <DialogContent id="simple-dialog-title" style={{ wordWrap: "break-word" }}>
                                    {window.location.protocol +
                                        "//" +
                                        window.location.host +
                                        "/Playlist?" +
                                        molecules.map(m => m.id + "," + m.repeat).join("&")}
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
