import React, { useState, useEffect } from "react";
import { db, setDoc, getDoc, getCollection, listenCollection } from "../../firestore";
import LearnCard from "./LearnCard.js";
import LearnCardAdd from "./LearnCardAdd.js";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Dialog from "./Dialog";
const courseColors = ["#1a73e8"];

export default props => {
    const user = useSelector(state => state.login.user);
    const [groups, setGroups] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    useEffect(
        () =>
            listenCollection(db.collection("users_groups").where("userId", "==", user.id), async usersGroups => {
                if (usersGroups.length === 0) {
                    setGroups([]);
                    const ownGroups = await getCollection(db.collection("groups").where("owner", "==", user.id));
                    if (ownGroups.length === 0) setOpenDialog(true);
                    return;
                }
                const g = [];
                const groupIds = [...new Set(usersGroups.map(userGroup => userGroup.groupId))];
                await Promise.all(
                    groupIds.map(async groupId => {
                        const doc = await getDoc(db.collection("groups").doc(groupId));
                        if (doc) g.push(doc);
                    })
                );
                setGroups(g.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0)));
            }),
        [user.id]
    );
    return (
        <>
            <Dialog
                open={openDialog}
                handleClose={() => props.setTab("public")}
                handleJoin={async code => {
                    const doc = await db.collection("groups").doc(code).get();
                    if (!doc.exists) {
                        alert("Group does not exist");

                        return;
                    }
                    const userGroup = { userId: user.id, groupId: code };
                    if (
                        (
                            await db
                                .collection("users_groups")
                                .where("userId", "==", userGroup.userId)
                                .where("groupId", "==", userGroup.groupId)
                                .get()
                        ).docs.length > 0
                    ) {
                        alert("You have already joined this group");
                        return;
                    }
                    const docRef = db.collection("users_groups").doc();
                    setDoc(docRef, userGroup);
                    setOpenDialog(false);
                }}
            />
            <p>Recent</p>

            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(275px, 1fr))",
                    gridGap: "2.5rem",
                    padding: "25px 0 25px 0"
                }}>
                <LearnCardAdd
                    onClick={async () => {
                        const code = window.prompt("Paste share code");
                        if (!code) return;
                        const doc = await db.collection("groups").doc(code).get();
                        if (!doc.exists) {
                            alert("Group does not exist");
                            return;
                        }
                        const userGroup = { userId: user.id, groupId: code };
                        if (
                            (
                                await db
                                    .collection("users_groups")
                                    .where("userId", "==", userGroup.userId)
                                    .where("groupId", "==", userGroup.groupId)
                                    .get()
                            ).docs.length > 0
                        ) {
                            alert("You have already joined this group");
                            return;
                        }
                        const docRef = db.collection("users_groups").doc();
                        setDoc(docRef, userGroup);
                    }}
                />
                {groups.map((g, i) => (
                    <Link
                        to={{
                            pathname: "/Home/" + g.id
                        }}
                        key={i}>
                        <LearnCard
                            key={i}
                            group={g}
                            match={props.match}
                            color={courseColors[i % courseColors.length]}
                        />
                    </Link>
                ))}
            </div>
        </>
    );
};
