import React from "react";
import Navigator from "./components/navigator/Navigator.js";
import Documentation from "./documentation/Documentation.js";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./GlobalStyle.css";
import Frontpage from "./components/Frontpage.js";
import Analytics from "./Analytics.js";
import { createStore } from "redux";
import rootReducer from "./reducers";
import { Provider } from "react-redux";
import Demo from "./components/Demo";
import CreatePlaylist from "./components/playlist/CreatePlaylist";
import Playlist from "./components/playlist/Playlist";

const theme = createTheme({
    palette: {
        primary: {
            main: "#96e8a6"
        },
        menu: {
            main: "#e0e0e0"
        }
    },
    overrides: {
        MuiTableRow: {
            root: {
                "&:last-child td": {
                    borderBottom: 0
                }
            }
        }
    }
});

const store = createStore(rootReducer);

const App = connect(state => state)(props => {
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route
                        render={props => (
                            <>
                                <Switch>
                                    <Route path="/" exact component={Frontpage} />
                                    <Route path="/Demo" exact component={Demo} />
                                    <Route path="/documentation" component={Documentation} />
                                    <Route path="/CreatePlaylist" exact component={CreatePlaylist} />
                                    <Route path="/Playlist" exact component={Playlist} />
                                    <Route path="/" component={Navigator} />
                                </Switch>
                                <Analytics />
                            </>
                        )}
                    />
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>
    );
});

export default props => (
    <Provider store={store}>
        <App />
    </Provider>
);
