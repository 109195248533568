import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import { Button, Typography, Popover } from "@material-ui/core";
import { Delete, GroupAdd } from "@material-ui/icons";
import GroupMenu from "./GroupMenu.js";
import { db, getDoc, setDoc } from "../../firestore";
import TopBarTitle from "../navigator/TopBarTitle.js";

export default props => {
    const history = useHistory();
    const [group, setGroup] = useState(null);
    const [anchorElShare, setAnchorElShare] = useState(null);

    const handleOpenShare = event => {
        setAnchorElShare(event.currentTarget);
    };

    const handleCloseShare = () => {
        setAnchorElShare(null);
    };
    const openShare = Boolean(anchorElShare);

    useEffect(() => {
        (async () => await setGroup(await getDoc(db.collection("groups").doc(props.match.params.groupId))))();
    }, [props.match.params.groupId]);

    useEffect(() => {
        if (!group) return;
        const t = window.setTimeout(() => setDoc(db.collection("groups").doc(group.id), group), 1000);
        return () => window.clearTimeout(t);
    }, [group]);

    if (!group) return <></>;
    return (
        <>
            <TopBarTitle
                title={group.title}
                onChange={e => setGroup(update(group, { title: { $set: e.target.value } }))}
            />
            <div
                style={{
                    padding: "10px 24px 10px 24px",
                    backgroundColor: "#f4f4f4",
                    display: "flex",
                    justifyContent: "flex-end",
                    borderTop: "1px solid lightgrey",
                    borderBottom: "1px solid lightgrey"
                }}>
                <div>
                    <Button
                        style={{ margin: "0 10px 0 0" }}
                        onClick={handleOpenShare}
                        startIcon={<GroupAdd fontSize="small" />}>
                        Share
                    </Button>
                    <Popover
                        open={openShare}
                        anchorEl={anchorElShare}
                        onClose={handleCloseShare}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}>
                        <Typography style={{ padding: "16px" }}>Code to students: {group.id}</Typography>
                    </Popover>
                    <Button
                        color="primary"
                        style={{ color: "#fff", margin: "0 10px 0 0" }}
                        variant="contained"
                        onClick={() => history.push("/Home/" + group.id)}>
                        Test as student
                    </Button>
                    <Button
                        variant="contained"
                        style={{ color: "#fff", margin: "0 10px 0 0" }}
                        onClick={async e => {
                            e.stopPropagation();
                            setGroup(update(group, { active: { $set: group.active === false } }));
                        }}>
                        {group.active === false ? "Activate" : "Deactivate"}
                    </Button>
                    {group.active === false && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={async e => {
                                e.stopPropagation();
                                if (!window.confirm("Delete " + group.title + "?")) return;
                                await db.collection("groups").doc(group.id).delete();
                                history.push("/Home");
                            }}
                            startIcon={<Delete />}>
                            Delete
                        </Button>
                    )}
                </div>
            </div>
            <GroupMenu
                group={group}
                groupId={props.match.params.groupId}
                update={selected =>
                    setGroup(
                        update(group, {
                            doodles: {
                                $set: selected
                            }
                        })
                    )
                }
            />
        </>
    );
};
