import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Tooltip } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Render from "../exercise/Render.js";
import { getProgressColor } from "../../utils";

const useStyles = makeStyles(theme => ({
    root: { display: "flex", flexGrow: 1 },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        flex: 1,
        boxShadow: "none",
        color: "#000"
    }
}));

const TableElement = props => {
    const classes = useStyles();
    const frac = props.progress.frac || 0;
    const failFrac = props.progress.failFrac || 0;
    const col = getProgressColor(frac, failFrac);
    return (
        <Grid item style={{ flex: (props.sum ? "1" : "0") + " 0 32px" }}>
            <Tooltip title={<Render text={props.title} />}>
                <Paper
                    className={classes.paper}
                    style={{
                        width: "100%",
                        background:
                            `linear-gradient(90deg, ${col} 0%, ${col}, ` +
                            100 * frac +
                            `%, ${col} ` +
                            100 * frac +
                            "%, transparent " +
                            100 * frac +
                            "%, transparent 100%)"
                    }}>
                    {Math.round((frac || 0) * 100) + "%"}
                </Paper>
            </Tooltip>
        </Grid>
    );
};

const TableRow = props => {
    const classes = useStyles();
    const blank = new Array(props.size - props.columns.length).fill();
    return (
        <Grid container item>
            <Grid item style={{ flex: "0 0 200px" }}>
                <Paper className={classes.paper}>{props.title}</Paper>
            </Grid>
            {props.columns.map(c => (
                <TableElement key={"tableElement" + c.id} title={c.title} progress={props.progress[c.id] || {}} />
            ))}
            {blank.map(c => (
                <Grid item style={{ flex: "0 0 32px" }}>
                    <Tooltip title={<Render text={props.title} />}>
                        <Paper
                            className={classes.paper}
                            style={{
                                width: "100%"
                            }}></Paper>
                    </Tooltip>
                </Grid>
            ))}
            <TableElement progress={props.sum} title="Total" sum={true} />
        </Grid>
    );
};

export default props => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    let size = 0;
    props.rows.forEach(r => (size = Math.max(size, r.columns.length)));

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Grid container spacing={1} onClick={() => setOpen(!open)}>
                    <Grid container item>
                        <Grid item style={{ flex: "0 0 200px" }}>
                            <Paper className={classes.paper}>{props.title}</Paper>
                        </Grid>
                        <TableElement progress={props.sum} title="Total" sum={true} />
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={1}>
                    {props.rows.map(r => (
                        <TableRow
                            key={"tableRow" + r.id}
                            title={r.title}
                            progress={props.progress[r.id] || {}}
                            columns={r.columns}
                            size={size}
                            sum={r.sum}
                        />
                    ))}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};
