import React from "react";
import { useHistory } from "react-router-dom";
import { db } from "../../firestore";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { Delete, NoteAdd } from "@material-ui/icons";

export default props => {
    const history = useHistory();
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "10px 24px 10px 24px",
                backgroundColor: "#f4f4f4",
                borderBottom: "1px solid lightgrey",
                borderTop: "1px solid lightgrey"
            }}>
            <Button
                color="default"
                startIcon={<NoteAdd />}
                style={{ marginRight: "auto" }}
                onClick={props.onAddPageRequest}>
                Add Page
            </Button>
            <FormControlLabel
                control={
                    <Switch
                        checked={props.doodle.public || false}
                        color="primary"
                        onClick={e => {
                            e.stopPropagation();
                            props.setDoodle(e);
                        }}
                    />
                }
                label="Public"
            />
            <Button
                variant="contained"
                color="secondary"
                startIcon={<Delete />}
                onClick={async e => {
                    e.stopPropagation();
                    if (!window.confirm("Delete the book " + props.doodle.title + "?")) return;
                    await db.collection("doodles").doc(props.doodle.id).delete();
                    history.push("/MyBooks");
                }}>
                Delete
            </Button>
        </div>
    );
};
