import React, { useState, useRef } from "react";
import CheckButton from "./CheckButton.js";
import styled from "styled-components";
import { renderVars, getVarNames } from "../exercise/Render.js";
import update from "immutability-helper";
import { Input } from "@material-ui/icons";
import { makeStyles, Typography, Popover, Button } from "@material-ui/core";

const InputField = styled.input`
    font-size: 15px;
    width: 100%;
    border-left: 4px solid #f0f0f0;
    border-top: 4px solid #f0f0f0;
    border-bottom: 4px solid #f0f0f0;
    border-right: none;
    &:focus {
        outline: none;
    }
`;

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: "none"
    },
    giveUp: {
        borderTop: "4px solid #f0f0f0",
        borderBottom: "4px solid #f0f0f0",
        borderRight: "4px solid #f0f0f0",
        transition: "none"
    }
}));

const pruneAnswer = input => input.replace(/\s/g, "").toLowerCase();

const checkIsCorrect = (answer, correctAnswer) => {
    const answerPruned = pruneAnswer(answer);
    const correctAnswerPruned = pruneAnswer(correctAnswer);
    if (answerPruned === correctAnswerPruned) return true;
    if (isNaN(Number(correctAnswer))) return false;
    return Number(answer.replaceAll(",", ".")) === Number(correctAnswer);
};

export default props => {
    const classes = useStyles();
    const [answer, setAnswer] = useState("");
    const [answerHistory, setAnswerHistory] = useState([]);
    const checkButton = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [correctIndicator, setCorrectIndicator] = useState(false);

    const rendered = renderVars({
        text: props.atom.correct,
        variables: props.random
    });

    return (
        <div style={{ display: "flex" }}>
            <InputField
                style={{ flex: 1 }}
                placeholder="Skriv svar ..."
                value={answer}
                onFocus={e => (e.target.placeholder = "")}
                onKeyDown={event => {
                    if (event.key === "Enter" && checkButton.current) checkButton.current.click();
                }}
                onChange={e => setAnswer(e.target.value)}
                aria-owns={anchorEl ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={event => {
                    setAnchorEl(event.currentTarget);
                }}
                onMouseLeave={() => setAnchorEl(null)}
            />
            <Button
                className={classes.giveUp}
                onClick={() => {
                    if (!window.confirm("Er du sikker?")) return;
                    props.registerEvent({ type: "giveUp" });
                    alert("Det korrekte svar var: " + rendered);
                }}>
                Giv op?
            </Button>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                onClose={() => setAnchorEl(null)}
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus>
                <Typography>
                    {props.showCorrect ? rendered : `Forrige forkerte svar: ${answerHistory.join(", ")}`}
                </Typography>
            </Popover>
            <CheckButton
                ref={checkButton}
                correct={correctIndicator}
                checkAnswers={() => {
                    const correct = checkIsCorrect(answer, rendered);
                    if (!correct) setAnswerHistory(update(answerHistory, { $push: [answer] }));
                    props.registerEvent({ type: correct ? "correct" : "incorrect" });
                    setCorrectIndicator(correct);
                    checkButton.current.startAnimation(correct);
                    setTimeout(() => {
                        if (correct) props.done(1);
                    }, 400);
                }}
            />
        </div>
    );
};

export const InputEdit = props => {
    return (
        <InputField
            placeholder="Skriv svar ..."
            onFocus={e => (e.target.placeholder = "")}
            value={props.atom.correct}
            onChange={e =>
                props.didUpdate(
                    update(props.atom, {
                        correct: { $set: e.target.value },
                        variables: { $set: getVarNames(e.target.value) }
                    })
                )
            }
        />
    );
};

export const InputIcon = props => <Input />;
