import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { db, getDoc, setDoc } from "../../firestore";
import { Button, IconButton, Tooltip, Container, Divider, TextField, Typography } from "@material-ui/core";
import { HelpOutline, Error, Check } from "@material-ui/icons";
import AtomFocusIndicator from "./AtomFocusIndicator";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Exercise from "../exercise/Exercise";
import Atom, { atomName, atomIcon, atomTypes } from "../atoms/Atom";
import SimpleBar from "simplebar-react";
import SetVariables from "./SetVariables.js";
import DraggableList from "react-draggable-list";
import Tags from "../Tags";
import TopBarTitle from "../navigator/TopBarTitle.js";
import { useErrorReportsForMoleulce } from "../../hooks/errorReports";

class DraggableAtom extends React.Component {
    render() {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>{this.props.item}</div>
                <div style={{ float: "right" }} {...this.props.dragHandleProps}>
                    <DragHandleIcon />
                </div>
            </div>
        );
    }
}

const Editor = props => {
    const [molecule, setMolecule] = useState(null);
    const [edit, setEdit] = useState(true);
    const [rowNumber, setRowNumber] = useState(0);
    const errorReports = useErrorReportsForMoleulce(molecule?.id);
    const [errorReporters, setErrorReporters] = useState({});

    useEffect(() => {
        (async () => {
            const molecule = await getDoc(db.collection("molecules").doc(props.match.params.moleculeId));
            molecule.atoms = molecule.atoms.map(atom => {
                atom.key = Math.random();
                return atom;
            });
            await setMolecule(molecule);
        })();
    }, [props.match.params.moleculeId]);

    useEffect(() => {
        (async () => {
            if (!molecule) return;
            const timeout = window.setTimeout(async () => {
                const mol = Object.assign({}, molecule);
                mol.atoms = mol.atoms.map(atom => {
                    const a = Object.assign({}, atom);
                    delete a.key;
                    return a;
                });
                await setDoc(db.collection("molecules").doc(props.match.params.moleculeId), mol).catch(err =>
                    alert(
                        "An error occurred while saving the page:\n\n" +
                            err +
                            "\n\nPlease contact us at nbitecodeteam@gmail.com"
                    )
                );
            }, 1000);
            return () => window.clearTimeout(timeout);
        })();
    }, [props.match.params.moleculeId, molecule]);

    useEffect(() => {
        if (!errorReports) return;
        (async () => {
            const docs = await Promise.all(errorReports.map(er => getDoc(db.collection("users").doc(er.user))));
            setErrorReporters(Object.fromEntries(docs.map(doc => [doc.id, doc])));
        })();
    }, [errorReports]);

    if (!molecule) return <></>;

    return (
        <>
            <TopBarTitle
                title={molecule.title}
                onChange={e => setMolecule(update(molecule, { title: { $set: e.target.value } }))}
            />
            <Divider />
            <div
                style={{
                    display: "flex",
                    height: "100px",
                    flexDirection: "column",
                    paddingTop: "10px",
                    backgroundColor: "#f4f4f4"
                }}>
                <div
                    style={{
                        padding: "5px 10px 5px 10px",

                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => setEdit(!edit)}>
                        {edit ? "Test mode" : "Edit mode"}
                    </Button>
                    {edit ? (
                        <Tags
                            selected={molecule.tags}
                            onChange={tags => setMolecule(update(molecule, { tags: { $set: tags } }))}
                        />
                    ) : (
                        <>
                            <TextField
                                type="number"
                                label="Variable row"
                                value={isNaN(rowNumber) ? "" : rowNumber + 1}
                                onChange={e => {
                                    const v = parseInt(e.target.value);
                                    setRowNumber(Math.min(Math.max(v - 1, 0), molecule.random.length - 1));
                                }}
                            />
                            {errorReports.length > 0 && (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {errorReports.map(er => (
                                        <Tooltip
                                            title={
                                                <div style={{ whiteSpace: "pre-line" }}>
                                                    {er.message}
                                                    <div style={{ color: "black" }}>
                                                        <br />
                                                        {errorReporters[er.user].name}
                                                        <br />
                                                        {errorReporters[er.user].email}
                                                    </div>
                                                </div>
                                            }>
                                            <Typography inline multiline>
                                                <Button
                                                    style={{ textTransform: "none" }}
                                                    onClick={() => {
                                                        setRowNumber(er.variableRow);
                                                    }}>
                                                    <Error color="secondary" />
                                                    {er.message.substring(0, 10)}
                                                    <Check
                                                        onClick={async () => {
                                                            if (!window.confirm("Remove error report?")) return;
                                                            await db.collection("errorReports").doc(er.id).delete();
                                                        }}
                                                    />
                                                </Button>
                                            </Typography>
                                        </Tooltip>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                    <div>
                        <Button
                            size="small"
                            style={{ float: "right" }}
                            startIcon={<HelpOutline fontSize="small" />}
                            onClick={() => window.open("/documentation", "_blank").focus()}>
                            Help
                        </Button>
                    </div>
                </div>
                <div style={{ flex: 1, padding: "5px 10px 5px 10px" }}>
                    {edit && (
                        <div style={{ float: "right", height: "100%", display: "flex", alignItems: "center" }}>
                            <SetVariables molecule={molecule} update={setMolecule} />{" "}
                        </div>
                    )}
                </div>
            </div>
            <Container
                maxWidth="lg"
                style={{ display: "flex", flexDirection: "column", overflowY: "hidden", backgroundColor: "#fff" }}>
                {edit ? (
                    <>
                        <div style={{ overflowY: "auto", flex: 1 }}>
                            <SimpleBar style={{ height: "100%", overflowX: "hidden", padding: "15px" }}>
                                <DraggableList
                                    itemKey="key"
                                    template={DraggableAtom}
                                    list={molecule.atoms.map((atom, i) => {
                                        if (!atom.key) atom.key = Math.random();
                                        return (
                                            <AtomFocusIndicator
                                                key={atom.key}
                                                initialVal={molecule.atoms.length - 1 === i}
                                                onDelete={() =>
                                                    setMolecule(update(molecule, { atoms: { $splice: [[i, 1]] } }))
                                                }>
                                                <Atom
                                                    atom={atom}
                                                    index={i}
                                                    didUpdate={newAtom => {
                                                        if (newAtom)
                                                            setMolecule(
                                                                update(molecule, { atoms: { [i]: { $set: newAtom } } })
                                                            );
                                                    }}
                                                    edit={true}
                                                />
                                            </AtomFocusIndicator>
                                        );
                                    })}
                                    onMoveEnd={async list => {
                                        const atomMap = {};
                                        molecule.atoms.forEach(a => {
                                            atomMap[a.key] = a;
                                        });
                                        await setMolecule(
                                            update(molecule, { atoms: { $set: list.map(l => atomMap[l.key]) } })
                                        );
                                    }}
                                    container={() => document.body}
                                />
                                <div style={{ display: "flex" }}>
                                    {atomTypes.map((type, i) => (
                                        <Tooltip key={i} title={atomName(type)}>
                                            <IconButton
                                                onClick={() =>
                                                    setMolecule(
                                                        update(molecule, { atoms: { $push: [{ type: type }] } })
                                                    )
                                                }
                                                component="span">
                                                {atomIcon(type)}
                                            </IconButton>
                                        </Tooltip>
                                    ))}
                                </div>
                            </SimpleBar>
                        </div>
                    </>
                ) : (
                    <SimpleBar style={{ height: "100%", overflowX: "hidden", padding: "15px" }}>
                        <Exercise molecule={molecule} {...props} seed={rowNumber || 0} showCorrect={true} />
                    </SimpleBar>
                )}
            </Container>
        </>
    );
};

export default Editor;
