import React, { useState, useEffect } from "react";
import { db, getDoc, getCollection } from "../../firestore";
import Statistics from "./Statistics";
import { Button } from "@material-ui/core";
import SimpleBar from "simplebar-react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

const useStyles = makeStyles(theme => ({
    toggleContainer: {
        margin: theme.spacing(2, 0),
        width: "100%"
    }
}));

const getSum = (progress, length) => {
    const sum = { count: 0, failCount: 0, frac: 0, failFrac: 0 };
    let green = 0;
    Object.keys(progress || {}).forEach(key => {
        const frac = progress[key].frac || 0;
        const failFrac = progress[key].failFrac || 0;
        sum.count += progress[key].count || 0;
        sum.failCount += progress[key].failCount || 0;
        sum.frac += Math.min(frac, 1);
        green += Math.min(frac, 1) / (failFrac / frac + 1) || 0;
    });
    green /= sum.frac;
    sum.frac /= length;
    sum.failFrac = (1 / green - 1) * sum.frac;
    return sum;
};

export default props => {
    const classes = useStyles();
    const [group, setGroup] = useState({
        doodles: []
    });
    const [users, setUsers] = useState([]);
    const [progress, setProgress] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [groupBy, setGroupBy] = useState("book");
    const [fullscreen, setFullscreen] = useState(false);
    const [data, setData] = useState([]);
    const [dataExtended, setDataExtended] = useState([]);

    useEffect(() => {
        switch (groupBy) {
            case "book":
                return setData(
                    group.doodles.map(doodle => {
                        return {
                            key: doodle.id,
                            title: doodle.title,
                            rows: users.map(u =>
                                Object.assign({ title: u.name || u.email || u.id, columns: doodle.molecules }, u)
                            ),
                            progress: progress[doodle.id] || {}
                        };
                    })
                );
            case "user":
                return setData(
                    users.map(user => {
                        const prog = {};
                        Object.keys(progress).forEach(key => {
                            prog[key] = progress[key][user.id];
                        });
                        return {
                            key: user.id,
                            title: user.name || user.email || user.id,
                            rows: group.doodles.map(d => Object.assign({ columns: d.molecules }, d)),
                            progress: prog
                        };
                    })
                );
            default:
                return setData([]);
        }
    }, [groupBy, group.doodles, users, progress]);

    useEffect(() => {
        setDataExtended(
            data.map(d => {
                const dNew = Object.assign({}, d);
                dNew.rows = d.rows.map(row => {
                    const rowNew = Object.assign({}, row);
                    rowNew.sum = getSum(d.progress[row.id], row.columns.length);
                    return rowNew;
                });
                dNew.sum = getSum(
                    dNew.rows.map(r => r.sum),
                    dNew.rows.length
                );
                return dNew;
            })
        );
    }, [data]);

    const exportCsv = progress => {
        const data = dataExtended
            .map(d => {
                let size = 0;
                d.rows.forEach(r => (size = Math.max(size, r.columns.length)));
                return [
                    [`"${d.title}"`]
                        .concat("Correct")
                        .concat(new Array(size).fill(""))
                        .concat("Total")
                        .concat("")
                        .concat("Incorrect")
                        .concat(new Array(size).fill(""))
                        .concat("Total")
                ]
                    .concat(
                        d.rows.map(row =>
                            [`"${row.title}"`]
                                .concat("")
                                .concat(row.columns.map(col => (d.progress[row.id][col.id] || {}).count || 0))
                                .concat(new Array(size - row.columns.length).fill(""))
                                .concat([row.sum.count])
                                .concat("")
                                .concat("")
                                .concat(row.columns.map(col => (d.progress[row.id][col.id] || {}).failCount || 0))
                                .concat(new Array(size - row.columns.length).fill(""))
                                .concat([row.sum.failCount])
                        )
                    )
                    .concat([[]]);
            })
            .flat();
        const csvContent = "data:text/csv;charset=utf-8," + encodeURI(data.map(row => row.join(",")).join("\n"));
        const link = document.createElement("a");
        link.setAttribute("href", csvContent);
        const twoDigit = n => (n < 10 ? "0" + n : n.toString());
        const now = new Date();
        const year = now.getFullYear().toString().substr(-2);
        const month = twoDigit(now.getMonth() + 1);
        const date = twoDigit(now.getDate());
        const hour = twoDigit(now.getHours());
        const minute = twoDigit(now.getMinutes());
        link.setAttribute("download", `${group.title}-${year}${month}${date}-${hour}${minute}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        (async () => {
            const group = await getDoc(db.collection("groups").doc(props.groupId));
            const doodles = await Promise.all(
                group.doodles.map(async doodle => {
                    if (typeof doodle.id === "number") return null;
                    const d = await getDoc(db.collection("doodles").doc(doodle.id));
                    d.molecules = await Promise.all(
                        d.molecules.map(async m => Object.assign(m, await getDoc(db.collection("molecules").doc(m.id))))
                    );
                    return d;
                })
            );
            group.doodles = [];
            doodles.forEach(doodle => {
                if (doodle) group.doodles.push(doodle);
            });
            await setGroup(group);
            const usersIds = [
                ...new Set(
                    (await getCollection(db.collection("users_groups").where("groupId", "==", props.groupId))).map(
                        userGroup => userGroup.userId
                    )
                )
            ];
            const us = await Promise.all(
                usersIds.map(async userId => await getDoc(db.collection("users").doc(userId)))
            );
            us.sort((a, b) => ((a.name || "").toLowerCase() > (b.name || "").toLowerCase() ? 1 : -1));
            await setUsers(us);
            await Promise.all(
                us.map(async u => {
                    const proAll = await getCollection(
                        db
                            .collection("progress")
                            .doc(u.id)
                            .collection("groups")
                            .doc(props.groupId)
                            .collection("doodles")
                    );
                    const proAllMap = {};
                    proAll.forEach(pro => {
                        proAllMap[pro.id] = pro;
                    });
                    group.doodles.forEach(async doodle => {
                        const pro = proAllMap[doodle.id] || {};
                        const count = pro.count || {};
                        const failCount = pro.failCount || {};
                        const maxCount = {};
                        doodle.molecules.forEach(m => (maxCount[m.id] = Number(m.repeat)));
                        const prog = {};
                        Object.keys(count).forEach(id => {
                            prog[id] = {
                                count: count[id],
                                failCount: failCount[id],
                                frac: count[id] / maxCount[id],
                                failFrac: (failCount[id] || 0) / maxCount[id]
                            };
                        });
                        setProgress(progress => {
                            if (!progress[doodle.id]) progress[doodle.id] = {};
                            progress[doodle.id][u.id] = prog;
                            return Object.assign({}, progress);
                        });
                    });
                })
            );
            setLoaded(true);
        })();
    }, [props.groupId]);

    if (!loaded) return <></>;
    return (
        <div
            style={{
                position: fullscreen ? "fixed" : "relative",
                zIndex: fullscreen ? 100000 : 1,
                top: 0,
                left: 0,
                backgroundColor: fullscreen ? "#fafafa" : "transparent",
                width: "100%",
                height: fullscreen ? "100%" : "auto",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
                padding: "0 30px 0 30px"
            }}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={12}>
                    <div className={classes.toggleContainer}>
                        <ToggleButtonGroup
                            value={groupBy}
                            exclusive
                            onChange={(event, value) => {
                                if (value) setGroupBy(value);
                            }}
                            aria-label="text alignment">
                            <ToggleButton value="book">Group by book</ToggleButton>
                            <ToggleButton value="user">Group by user</ToggleButton>
                        </ToggleButtonGroup>
                        <Button
                            style={{ float: "right" }}
                            startIcon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                            onClick={() => setFullscreen(!fullscreen)}>
                            {fullscreen ? " Exit Fullscreen" : "Fullscreen"}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
                {dataExtended.map(d => (
                    <Statistics {...d} />
                ))}
            </SimpleBar>
            <Button onClick={exportCsv}>Export CSV</Button>
        </div>
    );
};
