import React from "react";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import update from "immutability-helper";

export default props => {
    const history = useHistory();
    const user = useSelector(state => state.login.user);
    return (
        <>
            {!props.hidden && (
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#f4f4f4",
                        padding: "10px 24px 10px 24px",
                        borderBottom: "1px solid lightgrey",
                        borderTop: "1px solid lightgrey",
                        display: "flex"
                    }}>
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Repeat:</InputAdornment>
                        }}
                        style={{ flex: 1 }}
                        value={props.molecule.repeat}
                        onChange={e =>
                            props.onRepeatChange(update(props.molecule, { repeat: { $set: Number(e.target.value) } }))
                        }
                    />
                    {props.molecule.owner === user.id && (
                        <Button size="small" onClick={() => history.push("/MyPages/" + props.molecule.id)}>
                            Edit
                        </Button>
                    )}

                    <Button size="small" onClick={props.onRemove}>
                        Remove
                    </Button>
                </div>
            )}
        </>
    );
};
