import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography, Button, AppBar, Divider, Drawer, Hidden, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Loading from "../Loading.js";
import BookContentList from "./BookContentList.js";
import Exercise from "../exercise/Exercise.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { db, getDoc, listenDoc } from "../../firestore";
import { getProgressColor } from "../../utils";

const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        minHeight: "100%"
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: { zIndex: theme.zIndex.drawer + 1 },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none"
        },
        color: "#fff"
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        backgroundColor: "#fff"
    }
}));

const LearnNavigator = props => {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const user = useSelector(state => state.login.user);
    const history = useHistory();
    const [group, setGroup] = useState(null);
    const [doodle, setDoodle] = useState(null);
    const [index, setIndex] = useState(null);
    const [progress, setProgress] = useState(null);
    const [progressFailCount, setProgressFailCount] = useState(null);
    const [color, setColor] = useState(null);
    const [molecules, setMolecules] = useState(null);
    props.setBookOpen(true);

    useEffect(() => {
        (async () => {
            setGroup(await getDoc(db.collection("groups").doc(props.match.params.groupId)));
            const doodle = await getDoc(db.collection("doodles").doc(props.match.params.doodleId));
            doodle.molecules = doodle.molecules.map(m => {
                if (typeof m === "string") return { id: m, repeat: 1 };
                return { id: m.id, repeat: m.repeat };
            });
            setDoodle(doodle);
            let mol =
                doodle.molecules.length === 0
                    ? []
                    : await Promise.all(
                          doodle.molecules.map(async m => await getDoc(db.collection("molecules").doc(m.id)))
                      );
            const moleculeMap = {};
            doodle.molecules.forEach(m => {
                moleculeMap[m.id] = { repeat: m.repeat };
            });
            mol.forEach(m => {
                Object.assign(moleculeMap[m.id], m);
            });
            mol = doodle.molecules.map(m => moleculeMap[m.id] || { title: "This element does not exist", atoms: [] });
            setMolecules(mol);
        })();
    }, [props.match.params.doodleId, props.match.params.groupId, user.id]);

    useEffect(() => {
        if (!molecules) return;
        return listenDoc(
            db
                .collection("progress")
                .doc(user.id)
                .collection("groups")
                .doc(props.match.params.groupId)
                .collection("doodles")
                .doc(props.match.params.doodleId),
            async (p, updateCount) => {
                const count = p.count || {};
                const failCount = p.failCount || {};
                const col = {};
                molecules.forEach(m => {
                    if (count[m.id] === undefined) count[m.id] = 0;
                    if (failCount[m.id] === undefined) failCount[m.id] = 0;
                    col[m.id] = getProgressColor(count[m.id], failCount[m.id]);
                });
                let first = molecules.length - 1;
                molecules.forEach((m, i) => {
                    if (count[m.id] < m.repeat) first = Math.min(first, i);
                });
                if (updateCount === 1) setIndex(first);
                setProgress(count);
                setProgressFailCount(failCount);
                setColor(col);
            }
        );
    }, [props.match.params.doodleId, props.match.params.groupId, user.id, molecules]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;
    const hash = str => Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

    if (!molecules) return <Loading />;
    if (!progress) return <Loading />;
    if (!progressFailCount) return <Loading />;
    if (!color) return <Loading />;
    if (molecules.length === 0) return <div>This book is empty</div>;
    const molecule = molecules[index];
    const seed = molecule ? hash(user.id + group.id + doodle.id + molecule.id) : "";

    const drawer = (
        <div>
            <div className={classes.toolbar} style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}>
                <Typography variant="h6" noWrap>
                    {doodle.title}
                </Typography>
            </div>
            <Divider />
            <BookContentList
                molecules={molecules}
                progress={progress}
                progressFailCount={progressFailCount}
                color={color}
                molecule={molecule}
                index={index}
                setIndex={i => setIndex(i)}
            />
        </div>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: "#1a73e8" }}>
                <Toolbar>
                    <IconButton edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap style={{ color: "#fff", flexGrow: 1 }}>
                        {doodle.title}
                    </Typography>
                    <Button egde="end" style={{ color: "#fff" }} onClick={() => history.push("/Home/" + group.id)}>
                        Exit Book
                    </Button>
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            onBackdropClick: () => setMobileOpen(false)
                        }}>
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                {molecule && (
                    <Exercise
                        group={group}
                        doodle={doodle}
                        molecule={molecule}
                        {...props}
                        seed={seed}
                        key={seed + progress[molecule.id]}
                        showCorrect={false}
                        next={() => setIndex(index + 1)}
                        progress={progress}
                    />
                )}
            </main>
        </div>
    );
};

export default LearnNavigator;
