import React from "react";
import logo from "../img/o_logo_animation.svg";

export default () => {
    return (
        <div
            style={{
                position: "fixed",
                left: 0,
                top: 0,
                minHeight: "100%",
                minWidth: "100%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 99999
            }}>
            <img src={logo} width={200} alt="" />
        </div>
    );
};
