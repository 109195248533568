import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 10,
        borderRadius: 5,
        border: "1px solid lightgrey"
    },
    colorPrimary: {
        backgroundColor: "#fff"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary
    }
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    }
});

export default props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BorderLinearProgress variant="determinate" value={props.progress} />
        </div>
    );
};
