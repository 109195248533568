import React, { useState, useEffect, useRef } from "react";
import TopBarTitle from "../navigator/TopBarTitle.js";
import FindPage from "./FindPage.js";
import Exercise from "../exercise/Exercise.js";
import { db, getDoc, setDoc } from "../../firestore";
import update from "immutability-helper";
import TopToolbar from "./TopToolbar.js";
import BottomToolbar from "./BottomToolbar.js";
import PageList from "./PageList.js";
import AddPagePlaceholder from "./AddPagePlaceholder.js";

export default props => {
    const [addPageState, setAddPageState] = useState(true);
    const [doodle, setDoodle] = useState(null);
    const [scrolling, setScrolling] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const addPagePlaceholder = useRef(null);

    useEffect(() => {
        (async () => {
            const d = await getDoc(db.collection("doodles").doc(props.match.params.doodleId));
            d.molecules = d.molecules.map(m => {
                if (typeof m === "string") return { id: m, repeat: 1 };
                return { id: m.id, repeat: m.repeat };
            });
            d.molecules = await Promise.all(
                d.molecules.map(async m => Object.assign(m, await getDoc(db.collection("molecules").doc(m.id))))
            );
            await setDoodle(d);
        })();
    }, [props.match.params.doodleId]);

    useEffect(() => {
        if (!doodle) return;
        setAddPageState(doodle.molecules.length === 0);
    }, [doodle]);

    useEffect(() => {
        if (!doodle) return;
        const timeout = window.setTimeout(() => {
            setDoc(
                db.collection("doodles").doc(props.match.params.doodleId),
                update(doodle, {
                    molecules: {
                        $set: doodle.molecules.map(m => {
                            return { id: m.id, repeat: m.repeat || 1 };
                        })
                    }
                })
            );
        }, 1000);
        return () => window.clearTimeout(timeout);
    }, [doodle, props.match.params.doodleId]);

    useEffect(() => {
        if (addPagePlaceholder.current === null) return;
        if (!scrolling) return;
        setScrolling(false);
        addPagePlaceholder.current.scrollIntoView({ behavior: "smooth" });
    }, [scrolling]);

    if (!doodle) return <></>;
    return (
        <>
            <TopBarTitle
                title={doodle.title}
                onChange={e => setDoodle(update(doodle, { title: { $set: e.target.value } }))}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    minHeight: "100%"
                }}>
                <TopToolbar
                    doodle={doodle}
                    setDoodle={e => setDoodle(update(doodle, { public: { $set: e.target.checked } }))}
                    onAddPageRequest={() => {
                        setScrolling(true);
                        setAddPageState(true);
                        setCurrentPage(doodle.molecules.length);
                    }}
                />

                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        overflow: "hidden"
                    }}>
                    <div
                        style={{
                            maxWidth: 250,
                            overflowY: "auto",
                            padding: "10px 0 10px 0",
                            backgroundColor: "#f4f4f4",
                            height: "100%"
                        }}>
                        <PageList
                            currentPage={currentPage}
                            doodle={doodle}
                            setDoodle={e => {
                                if (!e.destination) return;
                                const i = e.source.index;
                                const j = e.destination.index;
                                if (i === j) return;
                                setDoodle(
                                    update(doodle, {
                                        molecules: {
                                            $splice: [
                                                [i, 1],
                                                [j, 0, doodle.molecules[i]]
                                            ]
                                        }
                                    })
                                );
                                setCurrentPage(j);
                                setAddPageState(false);
                            }}
                            changeIndex={i => {
                                setCurrentPage(i);
                                setAddPageState(false);
                            }}
                        />
                        <div ref={addPagePlaceholder}>
                            <AddPagePlaceholder hidden={!addPageState} />
                        </div>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto",
                                backgroundColor: "#fff"
                            }}>
                            {currentPage === doodle.molecules.length ? (
                                <FindPage
                                    visible={addPageState}
                                    onClose={() => alert("efe")}
                                    onAdd={m => {
                                        setDoodle(
                                            update(doodle, { molecules: { $push: [Object.assign({ repeat: 1 }, m)] } })
                                        );
                                        setScrolling(true);
                                        setAddPageState(false);
                                    }}
                                    noPagesYet={doodle.molecules.length === 0}
                                />
                            ) : (
                                <Exercise
                                    molecule={doodle.molecules[currentPage]}
                                    seed={Math.floor(Math.random() * doodle.molecules[currentPage].random.length)}
                                    hideTitle={false}
                                    showCorrect={true}
                                />
                            )}
                        </div>
                        {!addPageState && (
                            <BottomToolbar
                                hidden={addPageState}
                                molecule={doodle.molecules[currentPage]}
                                onRepeatChange={mol =>
                                    setDoodle(update(doodle, { molecules: { [currentPage]: { $set: mol } } }))
                                }
                                onRemove={() => {
                                    setDoodle(update(doodle, { molecules: { $splice: [[currentPage, 1]] } }));
                                    if (currentPage === 0) {
                                        setAddPageState(true);
                                        return;
                                    }
                                    setCurrentPage(currentPage - 1);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
