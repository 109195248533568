import React, { createContext, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { db } from "../firestore";

const Context = createContext(undefined);

export const useOwnMolecules = () => useContext(Context);

const maxDocCount = 10;

export const Provider = ({ children }) => {
    const user = useSelector(state => state.login.user);
    const [docs, setDocs] = useState(undefined);
    const [latestDoc, setLatestDoc] = useState(undefined);

    useEffect(() => {
        if (!user.id) return;

        (async () => {
            let colRef = db
                .collection("molecules")
                .where("owner", "==", user.id)
                .orderBy("updateTime", "desc")
                .limit(maxDocCount);

            if (latestDoc !== undefined) colRef = colRef.startAfter(latestDoc);

            const querySnapshot = (await colRef.get()).docs;
            const docData = [];
            let ld = undefined;
            querySnapshot.forEach(doc => {
                docData.push(Object.assign({ id: doc.id }, doc.data()));
                ld = doc;
            });
            if (docData.length === 0) return;
            setLatestDoc(ld);
            setDocs(docs === undefined ? docData : [...docs, ...docData]);
        })();
    }, [user?.id, docs]);

    useEffect(() => {
        console.log(docs);
    }, [docs]);

    return <Context.Provider value={docs}>{children}</Context.Provider>;
};
