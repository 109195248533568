import React, { useState, useEffect } from "react";
import JXG from "jsxgraph";

const JXGBoard = props => {
    const [id] = useState("board_" + Math.random().toString(36).substr(2, 9));

    useEffect(() => {
        setTimeout(() => {
            props.logic(JXG.JSXGraph.initBoard(id, props.boardAttributes));
        }, 100);
    }, []);

    return <div id={id} style={props.style} />;
};

export default JXGBoard;
