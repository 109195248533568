import React from "react";
import Layout from "../../constants/Layout.js";
import AccountSettingsMenu from "./AccountSettingsMenu.js";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${Layout.drawerWidth}px)`,
            marginLeft: Layout.drawerWidth
        },
        boxShadow: "none",
        zIndex: 1
    },
    menuButton: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));

export default props => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <AppBar position="fixed" style={{ backgroundColor: props.style.backgroundColor }} className={classes.appBar}>
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.handleDrawerToggle}
                    className={classes.menuButton}>
                    <MenuIcon style={{ color: props.style.color }} />
                </IconButton>

                <IconButton style={{ color: props.style.color }} onClick={() => history.goBack()}>
                    <ArrowBackIcon />
                </IconButton>

                <div style={{ flexGrow: 1 }}></div>

                <AccountSettingsMenu color={props.style.color} />
            </Toolbar>
        </AppBar>
    );
};
