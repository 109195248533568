import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import styled from "styled-components";
import "katex/dist/katex.min.css";
import Render, { getVarNames } from "../exercise/Render.js";
import update from "immutability-helper";
import { TextFields } from "@material-ui/icons";

const Textarea = styled.div`
    > textarea {
        width: 100%;
        border: none;
        outline: none;
    }
    line-height: 30px;
`;

export default props => {
    useEffect(() => {
        props.done(0);
    }, [props]);
    return (
        <Textarea>
            <Render text={props.atom.text} variables={props.random} />
        </Textarea>
    );
};

export const TextEdit = props => {
    return (
        <Textarea>
            <TextareaAutosize
                placeholder="Write some text..."
                value={props.atom.text}
                onChange={e =>
                    props.didUpdate(
                        update(props.atom, {
                            text: { $set: e.target.value },
                            variables: { $set: getVarNames(e.target.value) }
                        })
                    )
                }
            />
        </Textarea>
    );
};

export const TextIcon = props => <TextFields />;
