import React, { createContext, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { db, listenCollection } from "../firestore";

const Context = createContext(undefined);

export const useErrorReports = () => {
    const allErrorReports = useContext(Context);
    const [errorReports, setErrorReports] = useState(undefined);

    useEffect(() => {
        if (!allErrorReports) return;
        setErrorReports(
            allErrorReports.filter(
                er => er.groupOwnerMessage !== undefined || er.groupOwner === er.owner || er.groupOwner === undefined
            )
        );
    }, [allErrorReports]);

    return errorReports;
};

export const useErrorReportsForMoleulce = id => {
    const [result, setResult] = useState(undefined);
    const errorReports = useErrorReports();

    useEffect(() => {
        if (!errorReports) return;
        setResult(errorReports.filter(er => er.moleculeId === id));
    }, [id, errorReports]);

    return result;
};

export const Provider = ({ children }) => {
    const user = useSelector(state => state.login.user);
    const [docs, setDocs] = useState(undefined);

    useEffect(() => {
        if (!user.id) return;
        return listenCollection(
            db.collection("errorReports").where("owner", "==", user.id).orderBy("updateTime", "desc"),
            setDocs
        );
    }, [user?.id]);

    return <Context.Provider value={docs}>{children}</Context.Provider>;
};
