import React, { useState, useEffect } from "react";
import { makeStyles, List, ListItem, ListItemText, ListSubheader, Popover } from "@material-ui/core";
import Tags from "../Tags";
import { db, getCollection } from "../../firestore";
import PreviewPagePopover from "../books/PreviewPagePopover.js";
import Render from "../exercise/Render.js";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: "100%",
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #c4c4c4",
        borderRadius: 5
    },
    addButton: {
        position: "sticky",
        bottom: 0,
        height: 50,
        backgroundColor: "#96e8a6",
        color: "white",
        "&:hover": { backgroundColor: "#96e8a6" }
    },
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1)
    }
}));

export default props => {
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [molecules, setMolecules] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [moleculeHover, setMoleculeHover] = useState(null);
    const [allMolecules, setAllMolecules] = useState([]);

    useEffect(() => {
        getCollection(db.collection("molecules").where("public", "==", true)).then(setAllMolecules);
    }, []);

    useEffect(() => {
        setMolecules(allMolecules.filter(m => !tags.map(t => (m.tags || []).includes(t)).includes(false)));
    }, [allMolecules, tags]);

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMoleculeHover(null);
    };
    return (
        <div className={classes.root}>
            <List className={classes.list}>
                <ListSubheader>
                    <Tags onChange={setTags} />
                </ListSubheader>
                {molecules.map(molecule => (
                    <ListItem
                        key={molecule.id}
                        role={undefined}
                        dense
                        button
                        onClick={() => props.onAdd(molecule)}
                        aria-owns={!!anchorEl ? "mouse-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={event => {
                            setAnchorEl(event.currentTarget);
                            setMoleculeHover(molecule);
                        }}
                        onMouseLeave={handlePopoverClose}>
                        <ListItemText>
                            <Render text={molecule.title} />
                        </ListItemText>
                    </ListItem>
                ))}
                {moleculeHover && moleculeHover !== props.selectedMolecule && (
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                            paper: classes.paper
                        }}
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "left"
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus>
                        <PreviewPagePopover molecule={moleculeHover} />
                    </Popover>
                )}
            </List>
            <div style={{ minWidth: "100px" }} />
        </div>
    );
};
