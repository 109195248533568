import React from "react";
import { Button, Menu, MenuItem, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useSelector } from "react-redux";
import { auth } from "../../config";

export default props => {
    const user = useSelector(state => state.login.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                onClick={handleMenu}
                endIcon={<AccountCircle />}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{ color: props.color }}>
                <Hidden smDown implementation="css">
                    {user.name}
                </Hidden>
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={open}
                onClose={handleClose}>
                <Link
                    to={{
                        pathname: "/Profile"
                    }}>
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                </Link>

                <MenuItem
                    onClick={async () => {
                        window.localStorage.setItem("state", null);
                        await auth.signOut();
                        window.location.reload();
                    }}>
                    Log Out
                </MenuItem>
            </Menu>
        </div>
    );
};
