import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, CardContent, Typography, Button } from "@material-ui/core";
import { db, getDoc, setDoc } from "../../firestore";
import picture from "./math-basic.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: "#fff"
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

export default props => {
    const history = useHistory();
    const user = useSelector(state => state.login.user);
    const classes = useStyles();
    const [owner, setOwner] = useState("");

    useEffect(() => {
        (async () => {
            let user = await getDoc(db.collection("users").doc(props.group.owner));
            setOwner(user.name);
        })();
    }, [props.group.owner]);

    return (
        <Card className={classes.root} style={{ backgroundColor: props.color }}>
            <CardContent>
                {props.group.id === "ri8K5aNoZPPO3roERznn" && <img src={picture} style={{ maxWidth: "100%" }} alt="" />}
                <Typography variant="h5">{props.group.title}</Typography>
                <Typography variant="subtitle1">Created by: {owner}</Typography>
            </CardContent>
            <CardActions>
                {props.group.public && (
                    <Button
                        style={{ color: "#fff" }}
                        onClick={async event => {
                            event.preventDefault();
                            const title = window.prompt("Choose a title for your new group", props.group.title);
                            if (title === null) return;
                            const docRef = db.collection("groups").doc();
                            const group = {
                                id: docRef.id,
                                owner: user.id,
                                title: title,
                                doodles: props.group.doodles
                            };
                            await setDoc(docRef, group);
                            history.push("/MyGroup/" + group.id);
                        }}>
                        Import to My Groups
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};
