import React from "react";
import { Card, CardContent, CardActions, Typography } from "@material-ui/core";
import ProgressBar from "./ProgressBar.js";

export default props => {
    return (
        <div onClick={props.onClick} style={{ minHeight: "100%", cursor: "pointer", display: "contents" }}>
            <Card style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}>
                <CardContent>
                    <Typography variant="h6" color="textSecondary">
                        {props.title}
                    </Typography>
                </CardContent>
                <div style={{ flex: 1 }} />
                <CardActions>
                    <ProgressBar progress={props.progress} />
                </CardActions>
            </Card>
        </div>
    );
};
