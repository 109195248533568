import React, { useState, useEffect } from "react";
import { db, listenCollection } from "../../firestore";
import LearnCard from "./LearnCard";
import { Link } from "react-router-dom";

const courseColors = ["#1a73e8"];

export default props => {
    const [groups, setGroups] = useState([]);
    useEffect(() => listenCollection(db.collection("groups").where("public", "==", true), setGroups), []);
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(275px, 1fr))",
                gridGap: "2.5rem",
                padding: "25px 0 25px 0"
            }}>
            {groups.map((g, i) => (
                <Link
                    to={{
                        pathname: "/Home/" + g.id
                    }}
                    key={i}>
                    <LearnCard key={i} group={g} match={props.match} color={courseColors[i % courseColors.length]} />
                </Link>
            ))}
        </div>
    );
};
