import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import PageListItem from "./PageListItem.js";

export default props => {
    return (
        <DragDropContext onDragEnd={props.setDoodle}>
            <Droppable droppableId="droppable" direction="vertical">
                {propsDrop => (
                    <div
                        ref={propsDrop.innerRef}
                        style={{ display: "flex", flexDirection: "column" }}
                        {...propsDrop.droppableProps}>
                        {props.doodle.molecules.map((m, i) => (
                            <div
                                onClick={() => props.changeIndex(i)}
                                style={{
                                    padding: "10px 24px 0 24px",
                                    backgroundColor: props.currentPage === i ? "#96e8a6" : "transparent",
                                    cursor: "pointer"
                                }}>
                                <PageListItem molecule={m} index={i} />
                                <div
                                    style={{
                                        minHeight: "10px",
                                        justifyContent: "center",
                                        display: "flex",
                                        padding: "10px"
                                    }}>
                                    <p style={{ color: props.currentPage === i ? "#fff" : "inherit" }}>{i + 1}</p>
                                </div>
                            </div>
                        ))}
                        {propsDrop.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
