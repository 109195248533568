import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { db, increment } from "../firestore";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 500,
        "& > * + *": {
            marginTop: theme.spacing(3)
        }
    }
}));

export default props => {
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);
    const [newTags, setNewTags] = useState([]);
    const [text, setText] = useState("");

    useEffect(() => {
        (async () => {
            const t = props.tags || [];
            if (!props.tags) {
                const doc = (await db.collection("stats").doc("tags").get()).data();
                Object.keys(doc).forEach(key => {
                    t.push({ title: key, count: doc[key] });
                });
            }
            t.sort((a, b) => b.count - a.count);
            await setTags(t);
            await setSelected(
                (props.selected || []).map(v => {
                    let result = { title: v, count: 0 };
                    t.forEach(tt => {
                        if (tt.title === v) result = tt;
                    });
                    return result;
                })
            );
        })();
    }, [props.tags, props.selected]);

    const values = tags.concat(newTags);
    if (!props.tags && text && !values.map(v => v.title).includes(text))
        values.push({ title: text, count: 0, new: true });

    return (
        <Autocomplete
            className={classes.root}
            multiple
            options={values}
            getOptionLabel={option => {
                if (props.tags) return option.title + " (" + option.count + ")";
                return option.title;
            }}
            value={selected}
            onChange={async (e, value) => {
                if (value.length > selected.length)
                    await db
                        .collection("stats")
                        .doc("tags")
                        .set({ [value[value.length - 1].title]: increment }, { merge: true });
                let nt = [];
                value.forEach(v => {
                    if (v.new) nt = nt.concat(v);
                });
                await setNewTags(nt);
                await setSelected(value);

                if (props.onChange) props.onChange(value.map(v => v.title));
            }}
            onInputChange={(e, v) => setText(v)}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="standard"
                    label={selected === [] ? "Tags" : ""}
                    placeholder="Filtrer efter..."
                    InputLabelProps={{ shrink: false }}
                />
            )}
        />
    );
};
