import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { Menu } from "@material-ui/icons";
import DocSystemNavigator from "./DocSystemNavigator.js";
import DocWelcome from "./DocWelcome.js";
import Editor from "./Editor.js";
import Variables from "./Variables.js";
import Text from "./Atoms/Text.js";
import Answer from "./Atoms/Answer.js";
import Geogebra from "./Atoms/Geogebra.js";
import Hint from "./Atoms/Hint.js";
import Image from "./Atoms/Image.js";

export default props => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <AppBar position="static" color="menu">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setOpen(true)}>
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <DocSystemNavigator close={() => setOpen(false)} />
            </Drawer>
            <div style={{ overflowY: "auto", paddingBottom: "40px" }}>
                <Switch>
                    <Route path="/documentation" exact component={DocWelcome} />
                    <Route path="/documentation/editor" exact component={Editor} />
                    <Route path="/documentation/editor/atoms/text" exact component={Text} />
                    <Route path="/documentation/editor/atoms/answer" exact component={Answer} />
                    <Route path="/documentation/editor/atoms/geogebra" exact component={Geogebra} />
                    <Route path="/documentation/editor/atoms/hint" exact component={Hint} />
                    <Route path="/documentation/editor/atoms/image" exact component={Image} />
                    <Route path="/documentation/editor/variables" exact component={Variables} />
                </Switch>
            </div>
        </>
    );
};
