import React from "react";
import styled from "styled-components";
import AddBoxIcon from "@material-ui/icons/AddBox";

const Card = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    > span {
        display: none;
    }
    > h1 {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
    }
    > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 6px;
        border: #fff solid 10px;
        overflow: hidden;
        z-index: -1;
    }
`;

export default props => (
    <div onClick={() => props.onClick()}>
        <Card>
            <AddBoxIcon style={{ fontSize: 80, color: "#353839" }} />
        </Card>
    </div>
);
