import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    List,
    Paper,
    ListItem,
    Checkbox,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    ListSubheader,
    FormControlLabel,
    Button,
    Input,
    Popover
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { db, getCollection, getDoc } from "../../firestore";
import update from "immutability-helper";
import SimpleBar from "simplebar-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PreviewBookPopover from "../books/PreviewBookPopover";

const Search = styled.input`
    width: 100%;
    max-height: 30px;
`;

const DeleteOption = styled.div`
    display: ${props => "flex"};
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflowY: "auto",
        overflowX: "hidden",
        padding: "20px"
    },
    paper: {
        width: "100%",
        height: "100%",
        overflow: "auto"
    },
    button: {
        margin: theme.spacing(0.5, 0)
    },
    popover: {
        pointerEvents: "none"
    }
}));

const DraggableBook = props => (
    <Draggable
        key={props.book.id + "_" + props.index}
        draggableId={props.book.id + "_" + props.index}
        index={props.index}>
        {dragProps => (
            <div
                ref={dragProps.innerRef}
                {...dragProps.draggableProps}
                {...dragProps.dragHandleProps}
                style={dragProps.draggableProps.style}>
                <ListItem role="listitem">
                    {props.book.type === "section" && (
                        <DragHandleIcon
                            style={{
                                marginRight: "20px"
                            }}
                        />
                    )}
                    {props.book.type === "section" ? (
                        <Input
                            value={props.book.title}
                            style={{ width: "100%" }}
                            onChange={e => {
                                props.setSelected(
                                    update(props.selected, {
                                        [props.index]: {
                                            title: {
                                                $set: e.target.value
                                            }
                                        }
                                    })
                                );
                                props.setShouldSave(true);
                            }}
                        />
                    ) : (
                        <ListItemText
                            primary={`${props.book.title}`}
                            style={{
                                marginLeft: "44px"
                            }}
                        />
                    )}
                    <ListItemSecondaryAction>
                        <DeleteOption
                            style={{ width: "50px" }}
                            onClick={() => {
                                switch (props.book.type) {
                                    case "section":
                                        if (!window.confirm("Remove this heading from the group?")) return;
                                        break;
                                    default:
                                        if (!window.confirm("Remove this book from the group?")) return;
                                        break;
                                }
                                props.setSelected(
                                    update(props.selected, {
                                        $splice: [[props.index, 1]]
                                    })
                                );
                                props.setShouldSave(true);
                            }}>
                            <DeleteIcon />
                        </DeleteOption>
                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        )}
    </Draggable>
);

const TranferList = props => {
    const classes = useStyles();
    const user = useSelector(state => state.login.user);
    const [selected, setSelected] = useState([]);
    const [myBooks, setMyBooks] = useState([]);
    const [publicBooks, setPublicBooks] = useState([]);
    const [available, setAvailable] = useState([]);
    const [showOnlyMyBooks, setShowOnlyMyBooks] = useState(false);
    const [shouldSave, setShouldSave] = useState(false);
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [bookHover, setBookHover] = useState(null);

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setBookHover(null);
    };

    useEffect(() => {
        (async () => {
            setMyBooks(
                await getCollection(
                    db.collection("doodles").where("owner", "==", user.id).orderBy("updateTime", "desc")
                )
            );
            const publicBooks = await getCollection(
                db.collection("doodles").where("public", "==", true).orderBy("updateTime", "desc")
            );
            await Promise.all(
                publicBooks.map(async (book, i) => {
                    book.creator = (await getDoc(db.collection("users").doc(book.owner))).name;
                })
            );
            setPublicBooks(publicBooks);
        })();
    }, [user.id]);

    useEffect(() => {
        const allBooksMap = {};
        myBooks.forEach(book => (allBooksMap[book.id] = book));
        publicBooks.forEach(book => (allBooksMap[book.id] = book));
        const allBooks = Object.keys(allBooksMap).map(key => allBooksMap[key]);

        const sel = props.selected.map(book => {
            if (book.type === "section") return book;
            return allBooksMap[book.id] || { id: book.id, title: "!!! This element has been deleted !!!" };
        });
        setSelected(sel);
        const selectedMap = {};
        sel.forEach(s => (selectedMap[s.id] = s));

        const avail = [];
        allBooks.forEach(book => {
            if (selectedMap[book.id]) return;
            if (showOnlyMyBooks && book.owner !== user.id) return;
            if (!book.title.toLowerCase().includes(search.toLowerCase())) return;
            avail.push(book);
        });
        setAvailable(avail);
    }, [user.id, myBooks, publicBooks, showOnlyMyBooks, props.selected, search]);

    useEffect(() => {
        if (!shouldSave) return;
        setShouldSave(false);
        props.update(
            selected.map(element => {
                switch (element.type) {
                    case "section":
                        return { id: element.id, type: element.type, title: element.title };
                    default:
                        return { id: element.id };
                }
            })
        );
    }, [props, selected, shouldSave]);

    return (
        <>
            <Grid container spacing={2} justify="center" className={classes.root}>
                <Grid
                    item
                    xs={12}
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}>
                    <Paper
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflowY: "auto",
                            overflowX: "hidden"
                        }}>
                        <List
                            component="div"
                            role="list"
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                overflowY: "auto",
                                overflowX: "hidden"
                            }}>
                            <ListSubheader
                                style={{
                                    background: "#ffffff",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    zIndex: 1000
                                }}>
                                <p>Content of this group</p>
                                <Button
                                    float="right"
                                    onClick={() => {
                                        setSelected(
                                            update(selected, {
                                                $push: [
                                                    {
                                                        id: Math.random(),
                                                        type: "section",
                                                        title: ""
                                                    }
                                                ]
                                            })
                                        );
                                        setShouldSave(true);
                                    }}>
                                    Add section
                                </Button>
                            </ListSubheader>
                            <Divider />
                            <div style={{ height: "10px" }} />
                            <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
                                <DragDropContext
                                    onDragEnd={e => {
                                        if (!e.destination) return;
                                        const i = e.source.index;
                                        const j = e.destination.index;
                                        if (i === j) return;
                                        setSelected(
                                            update(selected, {
                                                $splice: [
                                                    [i, 1],
                                                    [j, 0, selected[i]]
                                                ]
                                            })
                                        );
                                        setShouldSave(true);
                                    }}>
                                    <Droppable droppableId="droppable" direction="vertical">
                                        {props => (
                                            <div ref={props.innerRef} {...props.droppableProps}>
                                                {selected.map((book, i) => (
                                                    <DraggableBook
                                                        key={book.id + "_" + i}
                                                        book={book}
                                                        index={i}
                                                        selected={selected}
                                                        setSelected={setSelected}
                                                        setShouldSave={setShouldSave}
                                                    />
                                                ))}
                                                {props.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </SimpleBar>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} justify="center" className={classes.root}>
                <Grid
                    item
                    xs={12}
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}>
                    <Paper
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflowY: "auto",
                            overflowX: "hidden"
                        }}>
                        <List
                            component="div"
                            role="list"
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                overflowY: "auto",
                                overflowX: "hidden"
                            }}>
                            <ListSubheader style={{ background: "#ffffff" }}>
                                <FormControlLabel
                                    control={<Checkbox onChange={() => setShowOnlyMyBooks(!showOnlyMyBooks)} />}
                                    label="Only show my books"
                                    value={showOnlyMyBooks}
                                />
                                <Search
                                    type="text"
                                    placeholder="Search.."
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />
                            </ListSubheader>
                            <Divider />
                            <div style={{ height: "10px" }} />
                            <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
                                {available.map(book => (
                                    <ListItem
                                        key={book.id}
                                        role="listitem"
                                        button
                                        onClick={() => {
                                            setSelected(update(selected, { $push: [book] }));
                                            setShouldSave(true);
                                        }}
                                        aria-owns={!!anchorEl ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={event => {
                                            setAnchorEl(event.currentTarget);
                                            setBookHover(book);
                                        }}
                                        onMouseLeave={handlePopoverClose}>
                                        <ListItemIcon>
                                            <ArrowBackIosIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`${book.title}`} secondary={book.creator} />
                                    </ListItem>
                                ))}
                                <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: props.noPagesYet ? "right" : "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: props.noPagesYet ? "left" : "right"
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus>
                                    {bookHover && <PreviewBookPopover key={bookHover.id} book={bookHover} />}
                                </Popover>
                            </SimpleBar>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default TranferList;
