import React from "react";
import { InlineMath } from "react-katex";

const varRegex = /@\s*(\w+)/g;

const renderVars = props => (props.text || "").replace(varRegex, (ignore, v) => props.variables[v]);

const getVarNames = text => [...text.matchAll(varRegex)].map(match => match[1]);

export default props => (
    <div className="notranslate">
        {renderVars(props)
            .split("$$")
            .map((text, i) => {
                if (i % 2 === 0) {
                    const result = text
                        .split("\n")
                        .map((e, j) => [<span key={"p" + i + "_" + j}>{e}</span>, <br key={"br" + i + "_" + j} />])
                        .flat();
                    result.pop();
                    return result;
                }
                return <InlineMath key={"math" + i} math={text} />;
            })
            .flat()}
    </div>
);

export { renderVars, getVarNames };
