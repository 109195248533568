import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Link } from "@material-ui/core";
import GroupOverviewCard from "./GroupOverviewCard.js";
import GroupTopicHeadline from "./GroupTopicHeadline.js";
import GroupBookPreview from "./GroupBookPreview.js";
import { db, listenDoc, getDoc, setDoc } from "../../firestore";

export default props => {
    const user = useSelector(state => state.login.user);
    const history = useHistory();
    const [group, setGroup] = useState(null);
    const [owner, setOwner] = useState("");
    const [doodles, setDoodles] = useState(null);
    const [progress, setProgress] = useState(null);
    const groupColor = "#1a73e8";

    useEffect(() => {
        props.changeTopBar({ color: "white", backgroundColor: groupColor });
    }, []);

    useEffect(() => {
        const unsubscribe = [
            listenDoc(db.collection("groups").doc(props.match.params.groupId), async group => {
                await setGroup(group);
                const doodles = await Promise.all(
                    group.doodles.map(async doodle => {
                        if (doodle.type === "section") return doodle;
                        return await getDoc(db.collection("doodles").doc(doodle.id));
                    })
                );
                const doodleMap = {};
                doodles.forEach(doodle => {
                    doodleMap[doodle.id] = doodle;
                });
                await setDoodles(
                    group.doodles.map(d => doodleMap[d.id || d] || { title: "This element does not exist" })
                );
                let userName = await getDoc(db.collection("users").doc(group.owner));
                setOwner(userName.name);
            }),

            listenDoc(
                db
                    .collection("progress")
                    .doc(user.id)
                    .collection("groups")
                    .doc(props.match.params.groupId),
                setProgress
            )
        ];
        return () => unsubscribe.map(u => u());
    }, [props.match.params.groupId, user.id]);

    if (!doodles) return <></>;
    if (!progress) return <></>;
    let sectionNumber = 0;
    return (
        <div style={{ overflowX: "hidden", display: "flex", flexDirection: "column" }}>
            <Grid container spacing={3} style={{ backgroundColor: groupColor, padding: "35px" }}>
                <Grid item xs={12} md={6}>
                    <p
                        style={{
                            fontSize: 14,
                            alignItems: "flex-start",
                            color: "#fff"
                        }}>
                        Owner: {owner}
                    </p>
                    <h3 style={{ color: "#fff", fontSize: "60px" }}>{group.title}</h3>
                    {!group.public && (
                        <Link
                            style={{ color: "#fff", textDecoration: "underline" }}
                            component="button"
                            variant="body2"
                            onClick={async () => {
                                if (!window.confirm("You are about to opt out of this group.")) return;
                                if (!window.confirm("Are you sure?")) return;
                                const docs = (await db
                                    .collection("users_groups")
                                    .where("userId", "==", user.id)
                                    .where("groupId", "==", group.id)
                                    .get()).docs;
                                await Promise.all(
                                    docs.map(
                                        async userGroup =>
                                            await db
                                                .collection("users_groups")
                                                .doc(userGroup.id)
                                                .delete()
                                    )
                                );
                                history.push("/Home");
                            }}>
                            Opt out of this group?
                        </Link>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {group.public && (
                        <GroupOverviewCard
                            doodles={doodles}
                            color={groupColor}
                            onCopy={async event => {
                                event.preventDefault();
                                const title = window.prompt("Choose a title for your new group", group.title);
                                if (title === null) return;
                                const docRef = db.collection("groups").doc();
                                const g = {
                                    id: docRef.id,
                                    owner: user.id,
                                    title: title,
                                    doodles: group.doodles
                                };
                                await setDoc(docRef, g);
                                history.push("/MyGroup/" + g.id);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container style={{ padding: "35px 35px" }}>
                {doodles.map((doodle, i) => {
                    switch (doodle.type) {
                        case "section":
                            sectionNumber++;
                            return (
                                <Grid key={i} item xs={12} style={{ marginTop: "35px", marginBottom: "15px" }}>
                                    <GroupTopicHeadline
                                        title={doodle.title}
                                        color={groupColor}
                                        sectionNumber={sectionNumber}
                                    />
                                </Grid>
                            );
                        default:
                            return (
                                <Grid key={i} item xs={12} md={6} lg={4} style={{ padding: "15px" }}>
                                    <GroupBookPreview
                                        title={doodle.title}
                                        color={groupColor}
                                        progress={!progress[doodle.id] ? 0 : 100 * progress[doodle.id]}
                                        onClick={() =>
                                            history.push("/Home/" + props.match.params.groupId + "/" + doodle.id)
                                        }
                                    />
                                </Grid>
                            );
                    }
                })}
            </Grid>
        </div>
    );
};
