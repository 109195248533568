import React, { useState } from "react";
import TopBarTabs from "../navigator/TopBarTabs.js";
import Joined from "./Joined";
import Public from "./Public";

export default props => {
    const [tab, setTab] = useState("joined");
    return (
        <>
            <TopBarTabs tab={tab} setTab={setTab} />
            <div style={{ padding: "16px 32px 16px 32px" }}>
                {tab === "joined" ? <Joined setTab={setTab} /> : <Public />}
            </div>
        </>
    );
};
