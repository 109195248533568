import React from "react";
import styled from "styled-components";
import { Typography, Container, Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import origo_logo from "./img/o_logo_1.svg";

const Ologo = styled.img`
    flex-shrink: 0;
    object-fit: "contain";
    @media (min-width: 960px) {
        width: 175px;
        height: 175px;
        margin-right: 64px;
    }
`;

const Welcome = styled.div`
    display: flex;
    width: 100%;

    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 960px) {
        text-align: center;
        align-items: center;
        padding-top: 32px;
        flex-direction: column;
        padding-bottom: 64px;
    }
    @media (min-width: 960px) {
        text-align: left;
        align-items: flex-start;
        padding-top: 128px;
        flex-direction: row;
        padding-bottom: 128px;
    }
    @media (min-width: 960px) {
        max-width: 960px;
    }
    @media (min-width: 600px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const WelcomeText = styled.div`
    > h3 {
        font-family: Roboto;
        font-weight: 300;
        margin-left: -12px;
        text-indent: 0.7rem;
        white-space: nowrap;
        letter-spacing: 0.7rem;
    }
    > h2 {
        font-size: 1.5rem;
    }
`;

const Card = styled.div`
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    border: 12px solid #fff;
    padding: 24px 16px;
    @media (min-width: 960px) {
        padding: 40px 32px;
    }
`;

export default props => {
    const history = useHistory();
    return (
        <div style={{ overflowY: "auto" }}>
            <Welcome>
                <Ologo src={origo_logo} height={175} width={175} />
                <WelcomeText>
                    <Typography variant="h3" gutterBottom>
                        ORIGO-DOC
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                        Get to learn the Origo Academy. Build your own teaching material and share it with the world.
                    </Typography>
                    <Button
                        variant="outlined"
                        style={{ marginTop: "32px" }}
                        onClick={() => {
                            history.push("/documentation/editor");
                        }}>
                        Get Started
                    </Button>
                </WelcomeText>
            </Welcome>
            <Container maxWidth="lg" disableGutters>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Typography variant="h6" gutterBottom>
                                Concept
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The Origo platform gives the tools to build (what we call) "Interactive Books". An
                                interactive book is a combination of both static readings and interactive assignemnts. A
                                book consists of digital pages that can be created by yourself or from what other users
                                shared.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Typography variant="h6" gutterBottom>
                                How it works
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Your job as a creator is to construct digital pages of the atoms available in the editor
                                (that we constantly try to expand). Every digital page have a headline, that makes it
                                easier for others to find relevant material in the database. The books is then created
                                by selecting pages from the public selection and self-created section.
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
