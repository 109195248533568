import React, { useEffect } from "react";
import { storage } from "../../config";
import CheckButton from "./CheckButton.js";
import update from "immutability-helper";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as GeogebraLogo } from "../../img/Geogebra.svg";

export default props => {
    useEffect(() => {
        (async () => {
            try {
                const file = await storage.ref().child(props.atom.ggb).getDownloadURL();
                const parameters = {
                    prerelease: false,
                    width: 800,
                    height: 600,
                    showToolBar: false,
                    borderColor: "#fff",
                    showMenuBar: false,
                    showAlgebraInput: false,
                    showResetIcon: true,
                    enableLabelDrags: false,
                    enableShiftDragZoom: true,
                    enableRightClick: false,
                    capturingThreshold: null,
                    showToolBarHelp: false,
                    errorDialogsActive: true,
                    useBrowserForJS: false,
                    filename: file
                };
                window.ggbApplet = new window.GGBApplet("5.0", parameters);
                window.ggbApplet.inject("applet_container");
            } catch (err) {
                console.log(err);
            }
        })();
    }, [props.atom.ggb, props.edit]);

    return (
        <div>
            <div id="applet_container"></div>
            <CheckButton
                checkAnswers={() => {
                    const correct = !!window.ggbApplet.getValue("correct");
                    props.registerEvent({ type: correct ? "correct" : "incorrect" });
                    if (correct) props.done(1);
                    return correct;
                }}
            />
        </div>
    );
};

export const GeogebraEdit = props => {
    return props.atom.ggb ? (
        props.atom.name
    ) : (
        <input
            type="file"
            onChange={async e => {
                const file = e.target.files[0];
                const id = Math.random().toString(36).substr(2, 9) + ".ggb";
                await storage.ref().child(id).put(file);
                props.didUpdate(update(props.atom, { ggb: { $set: id }, name: { $set: file.name } }));
            }}
        />
    );
};

export const GeogebraIcon = props => <SvgIcon component={GeogebraLogo} viewBox="0 0 300 276.6" />;
