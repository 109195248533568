import React from "react";
import styled from "styled-components";
import BookCoverStyle from "./BookCoverStyle.js";

const Container = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BookTitle = styled.div`
    color: white;
    word-wrap: break-word;
    font-size: 14px;
    padding: 5px;
`;

const bookColors = ["#d99d29"];

export default props => (
    <Container>
        <BookCoverStyle color={bookColors[props.i % bookColors.length]}>
            <div onClick={props.onClick}>
                <BookTitle>{props.doodle.title === "" ? "(no title)" : props.doodle.title}</BookTitle>
            </div>
        </BookCoverStyle>
    </Container>
);
