import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Render from "../exercise/Render.js";
import { makeStyles } from "@material-ui/core/styles";

const useStylesCustom = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    top: {
        color: theme.palette.primary,
        position: "absolute",
        left: 0
    },
    circle: {
        strokeLinecap: "round"
    }
}));

const CustomCircularProgress = props => {
    const classes = useStylesCustom();
    return (
        <div className={classes.root}>
            <CircularProgress
                variant="static"
                className={classes.bottom}
                size={35}
                thickness={4}
                value={100}
                {...props}
            />
            <CircularProgress
                variant="static"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle
                }}
                size={35}
                thickness={4}
                value={props.progress}
                {...props}
            />
        </div>
    );
};

export default props => (
    <List>
        {props.molecules.map((mol, i) => {
            const percent = (100 * props.progress[mol.id]) / mol.repeat;
            return (
                <ListItem
                    key={i}
                    onClick={() => props.setIndex(i)}
                    style={{ cursor: "pointer" }}
                    selected={i === props.index}>
                    <ListItemIcon>
                        {percent >= 100 ? (
                            <CheckCircleIcon fontSize="large" color="primary" />
                        ) : (
                            <CustomCircularProgress progress={percent} />
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        <Render text={mol.title} />
                    </ListItemText>
                </ListItem>
            );
        })}
    </List>
);
