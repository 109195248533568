import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, TextField, Typography, Button } from "@material-ui/core";
import { db, setDoc } from "../firestore";
import styled from "styled-components";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        paddingBottom: "80px"
    }
}));

const ReportErrorButton = styled.button`
    position: absolute;
    right: 16px;
    bottom: 16px;
    background-color: #353839;
    color: #fff;
    border: none;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
`;

const ReportError = props => {
    const user = useSelector(state => state.login.user);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMessage("");
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [message, setMessage] = useState("");

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Fejl i opgaven
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popOver}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}>
                <Typography className={classes.typography}>
                    <Typography variant="h6" gutterBottom>
                        Har du fundet en fejl i opgaven?
                    </Typography>
                    <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-static"
                        label="Skriv hvad fejlen er her"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        multiline
                    />
                    <ReportErrorButton
                        onClick={async () => {
                            if (!message) return;
                            setDoc(db.collection("errorReports").doc(), {
                                user: user?.id || "",
                                message,
                                groupId: props.group.id,
                                groupOwner: props.group.owner,
                                doodleId: props.doodle.id,
                                doodleOwner: props.doodle.owner,
                                moleculeId: props.molecule.id,
                                variableRow: props.variableRow ?? -1,
                                owner: props.molecule.owner
                            });
                            setAnchorEl(null);
                            setMessage("");
                            window.alert("Sendt!");
                        }}>
                        Send
                    </ReportErrorButton>
                </Typography>
            </Popover>
        </div>
    );
};

export default ReportError;
