import React, { useEffect, useState } from "react";
import { Divider, List, ListItem, ListItemText, ListItemIcon, ListSubheader } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Home, MenuBook, Create, AddBox } from "@material-ui/icons";
import Feedback from "../Feedback.js";
import { Link } from "react-router-dom";
import logo from "../../img/origo_logo.svg";

import { db, setDoc, listenCollection } from "../../firestore";

export default props => {
    const history = useHistory();
    let location = useLocation();
    const user = useSelector(state => state.login.user);
    const [myGroups, setMyGroups] = useState([]);

    useEffect(
        () =>
            listenCollection(
                db.collection("groups").where("owner", "==", user.id).orderBy("title", "asc"),
                setMyGroups
            ),
        [user.id]
    );

    const inactiveGroups = myGroups.filter(g => g.active === false);

    return (
        <div>
            <Link
                to={{
                    pathname: "/Home"
                }}>
                <div style={{ padding: "3.5px 0px" }}>
                    <img src={logo} height={50} alt="" />
                </div>
            </Link>
            <Divider />
            <List>
                <Link
                    to={{
                        pathname: "/Home"
                    }}
                    onClick={props.close}>
                    <ListItem button>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                </Link>
                <Link
                    to={{
                        pathname: "/MyBooks"
                    }}
                    onClick={props.close}>
                    <ListItem button>
                        <ListItemIcon>
                            <MenuBook />
                        </ListItemIcon>
                        <ListItemText primary="My Books" />
                    </ListItem>
                </Link>
                <Link
                    to={{
                        pathname: "/MyPages"
                    }}
                    onClick={props.close}>
                    <ListItem button>
                        <ListItemIcon>
                            <Create />
                        </ListItemIcon>
                        <ListItemText primary="My Pages" />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">My Groups</ListSubheader>}>
                <ListItem
                    button
                    onClick={async () => {
                        const docRef = db.collection("groups").doc();
                        const group = {
                            id: docRef.id,
                            owner: user.id,
                            title: "",
                            doodles: []
                        };
                        await setDoc(docRef, group);
                        history.push("/MyGroup/" + group.id);
                    }}>
                    <ListItemIcon>
                        <AddBox />
                    </ListItemIcon>
                    <ListItemText primary="Create Group" />
                </ListItem>
                {myGroups
                    .filter(g => g.active !== false)
                    .map((g, i) => (
                        <Link
                            to={{
                                pathname: "/MyGroup/" + g.id
                            }}
                            key={i}>
                            <ListItem button selected={location.pathname === "/MyGroup/" + g.id}>
                                <ListItemText primary={g.title === "" ? "NO TITLE" : g.title} />
                            </ListItem>
                        </Link>
                    ))}
                {inactiveGroups.length > 0 && (
                    <>
                        <Divider />
                        <ListSubheader component="div">Inactive Groups</ListSubheader>
                        {inactiveGroups.map((g, i) => (
                            <Link
                                to={{
                                    pathname: "/MyGroup/" + g.id
                                }}
                                key={i}>
                                <ListItem button selected={location.pathname === "/MyGroup/" + g.id}>
                                    <ListItemText primary={g.title === "" ? "NO TITLE" : g.title} />
                                </ListItem>
                            </Link>
                        ))}
                    </>
                )}
            </List>
            <div style={{ position: "absolute", left: "20px", bottom: 0, zIndex: 10 }}>
                <Feedback />
            </div>
        </div>
    );
};
