import React from "react";
import { Typography, Container, Button, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import abc from "./img/abc.png";
import variabletable from "./img/variabletable.png";
import variablerow from "./img/variablerow.png";

export default props => {
    const history = useHistory();
    return (
        <Container maxWidth="md">
            <div style={{ height: "40px" }} />
            <Typography variant="h3" gutterBottom>
                Setting Variables
            </Typography>
            <Typography variant="body1" gutterBottom>
                As explained in the the atom definitions, a variable can be defined with a @-sign. Hence, @V will define
                the variable V.
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="h4" gutterBottom>
                Variable Table
            </Typography>
            <Typography variant="body1" gutterBottom>
                All defined variables will be display in the variable table. Each row in the variable table will then
                represent af given instance of a page. The Variable table can be adjusted by clicking on{" "}
                <Button variant="contained">Set Variables</Button> in the Editor top. A example could be that the
                following{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/text")}>
                    Text Atom
                </Link>{" "}
                and{" "}
                <Link href="#" onClick={() => history.push("/documentation/editor/atoms/answer")}>
                    Answer Atom
                </Link>{" "}
                have been created on the page:
            </Typography>
            <img src={abc} height={150} alt="abc" />
            <Typography variant="body1" gutterBottom>
                The corresponding variable table would then be:
            </Typography>
            <img src={variabletable} height={150} alt="variable table" />
            <Typography variant="body1" gutterBottom>
                Lets then try to add two different rows:
            </Typography>
            <img src={variablerow} height={150} alt="variablerow" />
            <Typography variant="body1" gutterBottom>
                When users get the page, a random row is assigned to the user. One user might have to solve 2+2=4 and
                antoher 5+1=6.
            </Typography>
            <div style={{ height: "40px" }} />
            <Typography variant="body1" gutterBottom>
                In order to simplify the generation of this table, one can choose to upload a CSV file with the same
                columns as defined in the table.
            </Typography>
        </Container>
    );
};
