import React from "react";
import { Typography, Container } from "@material-ui/core";

export default props => (
    <Container maxWidth="md">
        <div style={{ height: "40px" }} />
        <Typography variant="h3" gutterBottom>
            Image Atom
        </Typography>
        <Typography variant="body1" gutterBottom>
            With the image atom you will be able to display any picture you like. Just upload the file and then it will
            be displayed when testing the page.
        </Typography>
    </Container>
);
